import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CLEAR_UNREAD_MESSAGE,
  GET_JOINGAME,
  GET_PLAYERMOVE,
  GET_TOURNAMENT,
  LEAVE_ROOM,
  ONLINE,
  POST_BLIND,
  PRESELECT_ACTION,
  REGISTER_SOCKET,
  RESET_MTT_INACTIVITY,
  RETURN_TABLE,
  SITOUT,
  SUBCRIBE_TO_GAME_UPDATES,
  TEXT_CHAT,
  UNSUBCRIBE_TO_GAME_UPDATES,
  VOTE_MULTI_RUN,
  WAIT_FOR_BB
} from 'constants/eventConstants';
import GameContext from 'contexts/GameContext';
import { SocketContext } from 'contexts/SocketContext';
import { useAppSelector } from 'hooks';
import { convertToNumber, encode, getCookie } from 'utils/functions';

const useGameService = () => {
  const socket = useContext(SocketContext);

  const {
    openAddChips,
    tableCards,
    setTableCards,
    playerMultiRunVote,
    setIsBlindPosted,
    setOpenAddChips,
    setPlayerMultiRunVote
  } = useContext(GameContext);

  const { tableId, gameType } = useParams();

  const guid = useAppSelector((state) => state.user.playerGUID);
  const avatar = useAppSelector((state) => state.user.playerAvatar);
  const username = useAppSelector((state) => state.user.playerName);
  const level = useAppSelector((state) => state.user.playerLevel);
  const displayName = useAppSelector((state) => state.user.displayName);

  const players = useAppSelector<any>((state) => state.currentTable.players);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const clubId = useAppSelector((state) => state.currentTable.currentPlayer.clubId);

  const bb = useAppSelector((state) => state.currentTable.game.bb);
  const card1 = useAppSelector((state) => state.currentTable.game.card1);
  const card2 = useAppSelector((state) => state.currentTable.game.card2);
  const card3 = useAppSelector((state) => state.currentTable.game.card3);
  const card4 = useAppSelector((state) => state.currentTable.game.card4);
  const card5 = useAppSelector((state) => state.currentTable.game.card5);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const gameID = useAppSelector((state) => state.currentTable.game.gameID);
  const linkedId = useAppSelector((state) => state.currentTable.game.tournamentId);
  const highestBetPlayer = useAppSelector((state) => state.currentTable.game.highestBetPlayer);
  const highestPlayerLBet = convertToNumber(highestBetPlayer?.betDisplay);

  const baseParams = useMemo(
    () => ({
      gameID: gameID,
      tableID: tableId,
      game: gameType,
      username: !username ? localStorage.getItem('playerName') : username,
      socketId: socket.id,
      data: '',
      playerGUID: !guid ? localStorage.getItem('playerGUID') : guid,
      level: level,
      seatId: seatId,
      clubId: clubId,
      language: 'en',
      displayName: displayName,
      tournamentId: linkedId,
      uid: '',
      deviceId: getCookie('_socketuuid')
    }),
    [gameID, guid, level, seatId, socket.id, username, gameType]
  );

  const joinTable = useCallback(
    (gameID: any, gameType: any) => {
      const params = {
        ...baseParams,
        gameID: gameID,
        game: gameType
      };

      socket.emit(ONLINE, params);
      socket.emit(SUBCRIBE_TO_GAME_UPDATES, params);
    },
    [socket, baseParams]
  );

  const leaveTable = useCallback(
    (exitFlag = false, immediate = false) => {
      socket.emit(SITOUT, {
        ...baseParams,
        data: `&leaveGame=true&exitFlag=${exitFlag}&immediate=${immediate}`
      });

      // New requirement, stood up the player when exiting the table.
      // Game tab is not closed but switched to observer mode.
      if (exitFlag) socket.emit(UNSUBCRIBE_TO_GAME_UPDATES, baseParams);
    },
    [baseParams, socket]
  );

  const unregister = useCallback(
    (tournamentId: any, gameID: any) => {
      socket.emit(GET_TOURNAMENT, {
        ...baseParams,
        data: `&action=unregister&tournamentId=${tournamentId}&game=texas&gameID=${gameID}`
      });
    },
    [baseParams, socket]
  );

  const subscribeTournamentDetails = useCallback(
    (tournamentId: any) => {
      socket.emit(REGISTER_SOCKET, {
        page: 'tournament',
        tournamentId: tournamentId,
        isRegister: true
      });
    },
    [baseParams, socket]
  );

  const unsubscribeTournamentDetails = useCallback(
    (tournamentId: any, page = 'tournament') => {
      socket.emit(LEAVE_ROOM, { page: page, tournamentId: tournamentId });
    },
    [baseParams, socket]
  );

  const register = useCallback(
    (tournamentId: any, gameID: any) => {
      socket.emit(GET_TOURNAMENT, {
        ...baseParams,
        data: `&action=register&tournamentId=${tournamentId}&game=texas&gameID=${gameID}`
      });
    },
    [baseParams, socket]
  );

  const structures = useCallback(
    (tournamentId: any) => {
      socket.emit(GET_TOURNAMENT, {
        ...baseParams,
        data: `&action=structures&tournamentId=${tournamentId}&game=texas`
      });
    },
    [baseParams, socket]
  );

  const tournamentPrizes = useCallback(
    (tournamentId: any) => {
      socket.emit(GET_TOURNAMENT, {
        ...baseParams,
        data: `&action=prizes&tournamentId=${tournamentId}&game=texas`
      });
    },
    [baseParams, socket]
  );

  const sitOut = useCallback(() => {
    socket.emit(SITOUT, {
      ...baseParams,
      data: '&leaveGame=false'
    });
  }, [baseParams, socket]);

  const imBack = useCallback(
    (seatId: number) => {
      socket.emit(RETURN_TABLE, {
        ...baseParams,
        data: `&pos=${seatId}`
      });
    },
    [baseParams, socket]
  );

  const sitDown = useCallback(
    (tableId: any, seatId: any, amount: any) => {
      const params = {
        ...baseParams,
        data: `&seat=${seatId}&buyin=${amount}&privateTable=0&clubId=0`
      };
      socket.volatile.emit(GET_JOINGAME, params);
    },
    [baseParams, socket]
  );

  const rebuy = useCallback(
    (seatId: any, amount: any, isRebuyFlag: boolean) => {
      const params = {
        ...baseParams,
        seatId: seatId,
        data: `&addon=${amount}&pos=${seatId}&isRebuyFlag=${isRebuyFlag}`
      };

      socket.emit(GET_PLAYERMOVE, params);
    },
    [baseParams, socket]
  );

  const addOn = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: `&action=addon&pos=${seatId}&playerGUID=${baseParams.playerGUID}&game=texas&gameID=${gameID}`
    };

    socket.emit(GET_TOURNAMENT, params);
  }, [baseParams, socket]);

  const tournamentRebuy = useCallback(
    (pSeatId: any, pGameID: any, pTournamentId: any) => {
      pSeatId = seatId === -1 ? pSeatId : seatId;
      pGameID = gameID === 0 ? pGameID : gameID;
      pTournamentId = +linkedId === 0 ? pTournamentId : linkedId;
      const params = {
        ...baseParams,
        seatId: seatId,
        data: `&action=rebuy&pos=${pSeatId}&playerGUID=${baseParams.playerGUID}&game=texas&gameID=${pGameID}&tournamentId=${pTournamentId}`
      };

      socket.emit(GET_TOURNAMENT, params);
    },
    [baseParams, socket]
  );

  const showCard = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&showcards=true'
    };

    socket.emit(GET_PLAYERMOVE, params);
  }, [baseParams, socket]);

  const muckCard = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&muckcards=true'
    };

    socket.emit(GET_PLAYERMOVE, params);
  }, [baseParams, socket]);

  const fold = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&action=fold&opt=&actionTrigger='
    };
    socket.emit(GET_PLAYERMOVE, params);
  }, [baseParams, socket]);

  const check = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&action=check&opt=&actionTrigger='
    };
    socket.emit(GET_PLAYERMOVE, params);
  }, [baseParams, socket]);

  const call = useCallback(() => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&action=call&opt=&actionTrigger='
    };
    socket.emit(GET_PLAYERMOVE, params);
  }, [baseParams, socket]);

  const raise = (amount: any) => {
    const action = highestPlayerLBet === 0 ? 'bet' : 'raise';
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&action=' + action + '&betamount=' + amount.toNumber() + '&type=' + action
    };
    socket.emit(GET_PLAYERMOVE, params);
  };

  const allIn = (amount: any) => {
    const params = {
      ...baseParams,
      seatId: seatId,
      data: '&action=allin&betamount=' + amount
    };
    socket.emit(GET_PLAYERMOVE, params);
  };

  const straddle = useCallback(
    (value: number) => {
      const params = {
        ...baseParams,
        seatId: seatId,
        data: '&straddle=' + value
      };
      socket.emit(GET_PLAYERMOVE, params);
    },
    [baseParams, socket]
  );

  const checkChips = () => {
    if (!openAddChips && seatId > 0 && players[seatId].bet === 'J0' && players[seatId].stack < bb)
      setOpenAddChips(true);
  };

  const getTableCards = useCallback(() => {
    switch (hand) {
      case '':
      case '1':
        setTableCards([]);
        break;
      case '6':
        setTableCards([...tableCards, card1, card2, card3]);
        break;
      case '8':
        setTableCards([...tableCards, card4]);
        break;
      case '10':
        setTableCards([...tableCards, card5]);
        break;
      case '15':
        setTableCards([]);
        break;
      default:
        break;
    }
  }, [card1, card2, card3, card4, card5, hand, setTableCards, tableCards]);

  const postBlind = useCallback(() => {
    const params = {
      ...baseParams
    };
    socket.emit(POST_BLIND, params);
    setIsBlindPosted(true);
  }, [baseParams, setIsBlindPosted, socket]);

  const waitForBB = useCallback(() => {
    const params = {
      ...baseParams
    };
    socket.emit(WAIT_FOR_BB, params);
  }, [baseParams, socket]);

  const multiRunVote = (vote: string) => {
    if (playerMultiRunVote === '0') {
      setPlayerMultiRunVote(vote);
      const params = {
        ...baseParams,
        data: `&vote=${vote}`
      };
      socket.emit(VOTE_MULTI_RUN, params);
    }
  };

  const textChat = (message: string) => {
    const params = {
      ...baseParams,
      avatar,
      displayName,
      data: `&txt=${encode(message)}`
    };

    socket.emit(TEXT_CHAT, params);
  };

  const clearUnreadMessage = (game: any, gameID: any, playerGUID: any) => {
    const params = {
      game,
      gameID,
      playerGUID
    };

    socket.emit(CLEAR_UNREAD_MESSAGE, params);
  };

  const resetMttInactivity = () => {
    const params = {
      ...baseParams,
      tableType: 'm'
    };

    socket.emit(RESET_MTT_INACTIVITY, params);
  };

  const preselectAction = (action: any) => {
    const params = {
      ...baseParams,
      data: '&action=' + action
    };
    socket.emit(PRESELECT_ACTION, params);
  };

  return {
    joinTable,
    leaveTable,
    sitOut,
    imBack,
    sitDown,
    rebuy,
    addOn,
    showCard,
    muckCard,
    fold,
    check,
    call,
    raise,
    allIn,
    straddle,
    checkChips,
    getTableCards,
    postBlind,
    multiRunVote,
    waitForBB,
    textChat,
    clearUnreadMessage,
    tournamentRebuy,
    unregister,
    register,
    structures,
    tournamentPrizes,
    subscribeTournamentDetails,
    unsubscribeTournamentDetails,
    resetMttInactivity,
    preselectAction
  };
};

export default useGameService;
