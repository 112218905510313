//Emitters
export const AVATAR_CHANGE = 'avatar_change';
export const CREATE_PLAYER = 'player_create';
export const CREATE_PLAYER_RESPONSE = 'createRsp';
export const GET_HEARTBEAT = 'get_heartbeat';
export const GET_HISTORY = 'get_history';
export const GET_LOBBY_DATA = 'lobby:v1:read';
export const GET_REPLAY = 'get_replay';
export const GET_TOURNAMENT = 'get_tournament';
export const INVALID_TOKEN = 'invalidToken';
export const LOGIN_PLAYER = 'get_login';
export const ONLINE = 'online';
export const SUBCRIBE_TO_GAME_UPDATES = 'SubscribeToGameUpdates';
export const UNSUBCRIBE_TO_GAME_UPDATES = 'UnsubscribeFromGameUpdates';
export const SITOUT = 'sitout';
export const RETURN_TABLE = 'returnTable';
export const GET_JOINGAME = 'get_joingame';
export const GET_PLAYERMOVE = 'get_playermove';
export const GET_PREFLOP_RANK = 'get_preFlopRank';
export const POST_BLIND = 'post_blind';
export const WAIT_FOR_BB = 'waitForBB';
export const VOTE_MULTI_RUN = 'vote_MultiRun';
export const TEXT_CHAT = 'textChat';
export const NOTIFY_UNREAD_MESSAGE = 'notifyUnreadMessage';
export const CLEAR_UNREAD_MESSAGE = 'clearUnreadMessage';
export const JOIN_MTT = 'join_mtt';
export const REGISTER_SOCKET = 'registerSocket';
export const TRACK_LOCATION = 'trackLocation';
export const RESET_MTT_INACTIVITY = 'resetMttInactivity';
export const LEAVE_ROOM = 'leaveRoom';
export const PRESELECT_ACTION = 'preselectAction';

//Listeners
export const AVATAR_CHANGE_RESPONSE = 'avatarChangeRsp';
export const CONNECT = 'connect';
export const RECONNECT = 'reconnect';
export const DISCONNECT = 'disconnect';
export const GOT_CHIPS_ADDED = 'gotChipsAdded';
export const GOT_CHARGED = 'gotCharged';
export const GOT_HEARTBEAT = 'gotHeartbeat';
export const GOT_HISTORY = 'gotHistory';
export const GOT_JOIN_GAME = 'gotJoinGame';
export const GOT_LOCATION = 'gotLocation';
export const GOT_LOGIN = 'gotLogin';
export const GOT_PLAYER_GAMES = 'got_playerGames';
export const GOT_PLAYER_MONEY = 'got_playerMoney';
export const GOT_LIVE_CHAT_NOTIFICATION = 'got_liveChatNotification';
export const GOT_RANK = 'gotRank';
export const GOT_TEXAS = 'gotTexas';
export const GOT_OMAHA = 'gotOmaha';
export const GOT_REFRESH_TOKEN = 'gotRefreshToken';
export const GOT_REPLAY = 'gotReplay';
export const GOT_TOURNAMENTS = 'gotTournamentH5';
export const GOT_TOURNAMENT_REGISTER = 'gotTnmRegister';
export const LOBBY_DATA_RECEIVED = 'lobby:v1:read:response';
export const GOT_MAINTENANCE_SCHEDULE = 'gotMaintenanceSchedule';
export const GOT_TOURNAMENT_UNREGISTER = 'gotTnmUnRegister';
export const GOT_TOURNAMENT_RANKING = 'gotTnmRanking';
export const GOT_TOURNAMENT_REGISTERED = 'gotTnmRegistered';
export const GOT_TOURNAMENT_PRIZES = 'gotTnmPrizes';
export const GOT_SERVER_TIME = 'gotServerDetails';
export const GOT_TOURNAMENT_STRUCTURE = 'gotTnmStructure';
export const GOT_TOURNAMENT_STATUS = 'gotTnmStatus';
export const GOT_TOURNAMENT_REGISTERED_PLAYERS = 'gotTnmRegisteredPlayers';
export const JOINED_MTT = 'joined_mtt';
export const GOT_TOURNAMENT_ADDON = 'gotTnmAddon';
export const GOT_TOURNAMENT_REBUY = 'gotTnmRebuy';
export const GOT_MULTIBAG_PRIZE = 'gotTnmMultiBagPayout';
export const GOT_TOURNAMENT_ACTIVE_TABLES = 'goTnmActiveTables';
export const GOT_TOURNAMENT_DETAILS = 'gotTnmDetails';
export const GOT_DEVICE_CONNECTED = 'deviceConnected';
export const GOT_DUPLICATE_ACCESS = 'duplicateAccessData';
export const FEATURE_FLAGS = 'featureFlags';
export const GOT_TOURNAMENT_LEANER_STATUS = 'gotTnmLeanerStatus';
