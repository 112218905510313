import React from 'react';
import { useAppSelector } from 'hooks';

import styles from './HandRank.module.scss';

const HandRank: React.FC = () => {
  const handRankDisplay = useAppSelector<any>((state) => state.currentTable.game.handRank);

  return (
    <>
      {!!handRankDisplay && (
        <div className={styles.HandRank}>
          <div className={styles.RankText}>{handRankDisplay}</div>
        </div>
      )}
    </>
  );
};

export default HandRank;
