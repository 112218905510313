import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { calculateMaxTopUp, convertToNumber, getPlayerStatus, parseOverBet } from 'utils/functions';

export type Player = {
  GUID: string;
  stack: number;
  bet: string;
  lbet: number;
  betDisplay: string;
  action: string;
  status: number;
  openAddFunds: boolean;
  totalBounty: number;
  totalHandBounty: number;
  isMuted: number;
  displayName: string;
  duration: string;
};

export type History = {
  msg: string;
  avatar?: string;
  systemMsg: boolean;
  isShowCard: boolean;
  playerCards?: CardHistory[];
  tableCards?: string[];
  totalPot?: string;
  isShowPot?: boolean;
  isCharge?: boolean;
};

export type CardHistory = {
  ID: string;
  bet: string;
  card1: string;
  card2: string;
  avatar: string;
  isFaceDownCard1: boolean;
  isFaceDownCard2: boolean;
};

export type Run = {
  run1: RunWinners;
  run2: RunWinners;
  run3: RunWinners;
};

export type RunWinners = {
  winners: number[];
  winTypes: string[];
};

export type Winner = {
  player: string;
  cards: string;
  hand: string;
  win: number;
  handType: string;
};

export type TablePot = {
  pot: number;
  players: number[];
  winners: Winner[];
  label: string;
};

export type ChatMessage = {
  GUID: string;
  displayName: string;
  avatar: string;
  messageGUID: string;
  message: string;
  ts: number;
};

interface PromoNotification {
  id: number;
  message: string;
}

const initialState = {
  game: {
    bb: 0,
    bet: 0,
    blindLevels: '',
    card1: '',
    card2: '',
    card3: '',
    card4: '',
    card5: '',
    dealer: '',
    game: '',
    gameID: 0,
    gameType: '',
    hand: '',
    isOmaha: false,
    isOmahaPL: false,
    languageKey: '',
    lastaction: '',
    lastmove: 0,
    lastbet: '',
    move: '',
    mraise: 0,
    msg: '',
    chargeMsg: '',
    overBet: '',
    pot: 0,
    potLimit: 0,
    p4pot: 0,
    p1potwin: 0,
    p2potwin: 0,
    p3potwin: 0,
    p4potwin: 0,
    p5potwin: 0,
    p6potwin: 0,
    p7potwin: 0,
    p8potwin: 0,
    p9potwin: 0,
    p10potwin: 0,
    runs: '',
    sb: 0,
    speed: 0,
    stack: '',
    tablelimit: 0,
    tablelow: 0,
    tablelowOverride: 0,
    triggerBuyIn: -1,
    time: 0,
    title: '',
    winner: '',
    winType1: '',
    winType2: '',
    winType3: '',
    winType4: '',
    winType5: '',
    winType6: '',
    winType7: '',
    winType8: '',
    winType9: '',
    p1bet: '',
    p2bet: '',
    p3bet: '',
    p4bet: '',
    p5bet: '',
    p6bet: '',
    p7bet: '',
    p8bet: '',
    p9bet: '',
    p10bet: '',
    p1lbet: 0,
    p2lbet: 0,
    p3lbet: 0,
    p4lbet: 0,
    p5lbet: 0,
    p6lbet: 0,
    p7lbet: 0,
    p8lbet: 0,
    p9lbet: 0,
    p10lbet: 0,
    winners: [] as number[],
    winTypes: [] as string[],
    potWins: [] as number[],
    totalPot: 0,
    lastplayer: '0',
    lastAllInPlayer: '0',
    highestBetPlayer: {
      GUID: '',
      lbet: 0,
      betDisplay: '',
      stack: 0,
      bet: '',
      action: '',
      status: 0,
      openAddFunds: false
    } as Player,
    tableOverBet: {
      seatId: 0,
      bet: 0
    },
    p1action: '',
    p2action: '',
    p3action: '',
    p4action: '',
    p5action: '',
    p6action: '',
    p7action: '',
    p8action: '',
    p9action: '',
    p10action: '',
    p1status: 0,
    p2status: 0,
    p3status: 0,
    p4status: 0,
    p5status: 0,
    p6status: 0,
    p7status: 0,
    p8status: 0,
    p9status: 0,
    multiRunCards: {},
    runWinners: {
      run1: {
        winners: [],
        winTypes: []
      },
      run2: {
        winners: [],
        winTypes: []
      },
      run3: {
        winners: [],
        winTypes: []
      }
    } as Run,
    pauseStartTime: 0,
    sBustPause: '0',
    pots: [] as TablePot[],
    maximumTopUp: 0,
    deadMoney: 0,
    isPennyTable: false,
    straddle: '',
    hasStraddle: '0|0',
    sbPlayer: '',
    bbPlayer: '',
    tabletype: '',
    startAtTime: 0,
    chatMessages: [] as ChatMessage[],
    lastChatMessage: null as ChatMessage | null,
    tournamentId: '',
    myStatus: '',
    status: '',
    closed: -1,
    seats: 0,
    registeredPlayers: 0,
    ante: 0,
    activePlayers: 0,
    nextSB: 0,
    nextBB: 0,
    nextAnte: 0,
    minPlayers: 0,
    prizePool: 0,
    guarantee: 0,
    currentLevel: 0,
    bust: {
      sBustPause: 0,
      sRebuyFlag: false,
      pauseStartTime: 0,
      elapsedTime: 0,
      duration: 0
    },
    strStartsIn: '',
    schedule: '',
    addOn: 0,
    addOnChips: 0,
    addOnPrice: 0,
    addOnExpireMins: 0,
    canAddOnChips: false,
    rebuyChips: 0,
    rebuyPrice: 0,
    rebuyExpireMins: 0,
    rebuys: 0,
    linkedId: 0,
    isBreak: 0,
    maxSeat: 0,
    nextLevelIsBreak: 0,
    isOnBreak: 0,
    levelStartedAt: 0,
    nextMins: 0,
    currentMins: 0,
    lvlDisplay: 0,
    totalAddOns: 0,
    totalRebuys: 0,
    isMultiFlightBreak: 0,
    currentFlight: 0,
    isMultiFlight: 0,
    nextLevel: '0',
    isFinalMultiFlight: 0,
    entryFee: 0,
    multiBagOption: '',
    isQualified: 0,
    maxSB: 0,
    maxBB: 0,
    maxAnte: 0,
    effectiveRate: 0,
    chargeFrequency: 0,
    minBalanceToPlay: 0,
    addFundsMsg: '',
    lateRegistrationCountDown: 0,
    currentSB: 0,
    currentBB: 0,
    currentAnte: 0,
    bountyAmount: 0,
    moveMsg: '',
    isPostBalancing: 0,
    buyInTriggerTimer: 120, // default value
    callAmount: 0,
    guidWinner: '',
    showdown: {
      currentPot: 0,
      currentStep: 0,
      handType: ''
    },
    seatsWithShowCard: [] as number[],
    seatsWithMuckCard: [] as number[],
    preselectedAction: '',
    p1preAction: '',
    p2preAction: '',
    p3preAction: '',
    p4preAction: '',
    p5preAction: '',
    p6preAction: '',
    p7preAction: '',
    p8preAction: '',
    p9preAction: '',
    p1hasAddOnChips: false,
    p2hasAddOnChips: false,
    p3hasAddOnChips: false,
    p4hasAddOnChips: false,
    p5hasAddOnChips: false,
    p6hasAddOnChips: false,
    p7hasAddOnChips: false,
    p8hasAddOnChips: false,
    p9hasAddOnChips: false,
    cardCount: 0,
    closedBetting: 0,
    handRank: '',
    waitlistCount: 0,
    isSatellite: 0
  },
  gameState: {
    startTurn: false,
    preDeal: false,
    noPlayers: 0,
    foldedPlayers: 0,
    notFoldedPlayers: 0,
    showStraddle: false,
    allInPlayers: 0,
    nextBBPlayer: 0,
    nextDealer: 0,
    nextStraddlePlayer: 0,
    ingamePlayers: 0,
    playerActiveInHand: 0,
    canBuyTheButton: true,
    gameEnding: false,
    playerActiveInHandNew: 0,
    isShowdown: false,
    startMucking: false,
    canExitTable: false,
    pokerActions: {
      allIn: false,
      bet: false,
      call: false,
      check: false,
      raise: false,
      fold: false,
      isMyTurn: false,
      preCall: false,
      preCallAny: false,
      muckCard: false,
      playerCallAmount: 0,
      showAction: false,
      showCard: false,
      showStraddle: false,
      betMultiplier: {
        min: 0,
        x2: 0,
        x3: 0,
        x5: 0,
        oneHalf: 0,
        oneFourth: 0,
        fullPot: 0,
        mraise: 0,
        playerTotalChips: 0,
        playersStack: 0,
        x2isDisabled: false,
        x3isDisabled: false,
        x5isDisabled: false,
        oneHalfIsDisabled: false,
        oneFourthIsDisabled: false,
        fullPotDisabled: false,
        allInDisabled: false,
        isFirstToActPostFlop: false,
        isAllIn: false,
        hasPotLimit: false
      }
    }
  },
  players: [] as Player[],
  ts: 0,
  // Custom Properties
  currentPlayer: {
    GUID: '',
    playerStatus: 0,
    seatId: -1,
    isMyTurn: false,
    isSeated: false,
    isMultiRunActive: false,
    isWaiting: false,
    isFolded: false,
    isSB: false,
    isBB: false,
    isPostedBlind: false,
    isStraddle: false,
    isPlayerInactive: false,
    isWaitingAndExit: false,
    playerBankAmount: 0,
    playerChargeBalance: 0,
    promoNotifications: [] as PromoNotification[],
    playerGUID: null,
    isJoined: false,
    isRejoining: false,
    clubId: 0,
    chargeCount: 0,
    seatStartTime: null as number | null,
    isMuted: 0,
    duration: ''
  },
  tableCharges: [],
  tableHistory: [] as History[],
  tournamentStatus: []
};

export const currentTableSlice = createSlice({
  name: 'currentTable',
  initialState,
  reducers: {
    updateCurrentTable: (state, action) => {
      const { game, gameState, players, tableCharges = [], tableHistory = [], ts } = action.payload;

      if (state.game.msg !== game.msg) {
        const historyItems = [...state.tableHistory, ...tableHistory];
        const historyItemsLen = historyItems.length;
        const historyStartIndex = Math.max(0, historyItemsLen - 100);
        state.tableHistory = historyItems.slice(historyStartIndex, historyItemsLen) as History[];
      }

      state.game = { ...state.game, ...game };
      state.game.isOmaha = state.game.game === 'omaha';
      state.gameState = { ...state.gameState, ...gameState };
      state.players = players;

      state.ts = ts;

      state.game.winners = parseWinnerToArray(state.game.winner);
      state.game.winTypes = mapWinTypeToArray(state.game);
      state.game.potWins = mapPlayerPotWinToArray(state.game);
      state.game.tableOverBet = parseOverBet(state.game.overBet);

      state.currentPlayer.isMultiRunActive = isMultiRunActive(state.game);

      const seatId = _.findIndex(
        state.players,
        (p: any) => !!p?.GUID && p?.GUID === state.currentPlayer.playerGUID
      );

      if (game.chatMessages.length > 0 && seatId > 0) {
        state.game.lastChatMessage = game.chatMessages
          ?.filter((f: ChatMessage) => !!f)
          .slice(-1)[0];
      } else {
        state.game.lastChatMessage = null;
      }

      state.currentPlayer.seatId = seatId;
      state.currentPlayer.isMyTurn = seatId === +state.game.move;
      state.currentPlayer.isSeated = seatId > 0;

      if (state.currentPlayer.isSeated && !state.currentPlayer.seatStartTime) {
        state.currentPlayer.seatStartTime = Date.now();
      }

      if (!state.currentPlayer.isSeated) {
        state.currentPlayer.seatStartTime = null;
      }

      state.tableCharges = { ...state.tableCharges, ...tableCharges };
      // state.currentPlayer.chargeCount = (state.tableCharges as any)[
      //   `p${state.currentPlayer.seatId}name`
      // ].multiplier;

      const { isWaiting } = getPlayerStatus(state.game, state.currentPlayer.seatId);

      const playerBetAction = (state.game as any)[`p${state.currentPlayer.seatId}bet`]?.substring(
        0,
        1
      );
      const playerAction = (state.game as any)[`p${state.currentPlayer.seatId}action`];
      const playerStatus = (state.game as any)[`p${state.currentPlayer.seatId}status`];
      state.currentPlayer.isWaiting = isWaiting;
      state.currentPlayer.isFolded = playerBetAction === 'F';
      state.currentPlayer.isSB = state.currentPlayer.seatId === +state.game.sbPlayer;
      state.currentPlayer.isBB = state.currentPlayer.seatId === +state.game.bbPlayer;
      state.currentPlayer.isPostedBlind = playerAction === 'ANTE';
      state.currentPlayer.isStraddle = playerAction === 'straddle';
      state.currentPlayer.isPlayerInactive = ['J', 'F'].includes(playerBetAction);
      state.currentPlayer.isJoined = playerBetAction === 'J';
      state.currentPlayer.isWaitingAndExit = [17, 18].includes(+playerStatus);
      state.currentPlayer.isRejoining = +playerStatus === 10;
      state.currentPlayer.playerStatus = +playerStatus;
      state.currentPlayer.isMuted = (state.currentPlayer as any)[
        `p${state.currentPlayer.seatId}status`
      ];
      state.currentPlayer.duration = (state.currentPlayer as any)[
        `p${state.currentPlayer.seatId}status`
      ];

      let ppot = (state.game as any)[`p${state.currentPlayer.seatId}pot`];
      ppot = state.game.isPennyTable ? convertToNumber(ppot) : parseInt(ppot);

      state.game.maximumTopUp = calculateMaxTopUp(
        state.game.tablelimit,
        ppot,
        state.currentPlayer.playerBankAmount
      );
    },

    updateCurrentPlayerGUID: (state, action) => {
      state.currentPlayer.playerGUID = action.payload;
    },

    addHistory: (state, action) => {
      const history = action.payload as History[];
      // const items = [...state.tableHistory, ...history];
      // const itemLen = items.length;
      // const startIndex = Math.max(0, itemLen - 100);

      state.tableHistory = state.tableHistory
        ? ([...state.tableHistory, ...history] as History[])
        : ([...history] as History[]);

      state.game.chargeMsg = history[0]?.msg;
    },

    updateCurrentPlayerBankAmount: (state, action) => {
      state.currentPlayer.playerBankAmount = action.payload?.response?.playWinpot;
      state.currentPlayer.playerChargeBalance = action.payload?.response?.playChargeBalance;

      // console.log(action.payload?.response);
      let ppot = (state.game as any)[`p${state.currentPlayer.seatId}pot`];
      ppot = state.game.isPennyTable ? convertToNumber(ppot) : parseInt(ppot);

      state.game.maximumTopUp = calculateMaxTopUp(
        state.game.tablelimit,
        ppot,
        state.currentPlayer.playerBankAmount
      );
    },

    updateTournamentStatus: (state, action) => {
      state.tournamentStatus = action.payload;
    },

    updateCurrentPlayerHasAddOnChips: (state, action) => {
      const seatId = action.payload;
      const game = { ...state.game, [`p${seatId}hasAddOnChips`]: true };

      state.game = game;
    }
  }
});

export const {
  updateCurrentTable,
  updateCurrentPlayerGUID,
  addHistory,
  updateCurrentPlayerBankAmount,
  updateTournamentStatus,
  updateCurrentPlayerHasAddOnChips
} = currentTableSlice.actions;

export default currentTableSlice.reducer;

const parseWinnerToArray = (winner: string) => {
  /*
   * Convert emtpy strings to -1 whenever we start introducing
   * vacant seats between players
   *
   * winner.map((string) => (string ? parseInt(string) : -1))
   *
   * Example:
   *   winners  = [2, 1, 4, -1, 5, -1, 3]
   *   -1 are empty seats
   */

  return winner.split(',').map((m) => +m);
};

const mapWinTypeToArray = (game: any) => {
  /*
   * Get winner types based on the winners[] array
   * Example:
   *   winners  = [  2,    1,    3,    4,    5]
   *   winTypes = [win, side, side, side, side]
   *
   *   player 2 is win, player 1 is side, player 3 is side, so on...
   */

  return [
    game.winType1,
    game.winType2,
    game.winType3,
    game.winType4,
    game.winType5,
    game.winType6,
    game.winType7,
    game.winType8,
    game.winType9
  ];
};

const mapPlayerPotWinToArray = (game: any) => {
  return [
    0,
    +game.p1potwin,
    +game.p2potwin,
    +game.p3potwin,
    +game.p4potwin,
    +game.p5potwin,
    +game.p6potwin,
    +game.p7potwin,
    +game.p8potwin,
    +game.p9potwin,
    +game.p10potwin
  ];
};

const isMultiRunActive = (game: any) => {
  return (game.runs?.split(',').length ?? 0) === 3;
};
