import { useCallback } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { currencyFormatter, toLocaleStringNumber } from 'utils/functions';

import 'components/UserActions/UserActions.scss';

interface CallProps {
  disabled: boolean;
  setIsActionDisabled: (value: boolean) => void;
}

const CallAction = ({ disabled, setIsActionDisabled }: CallProps) => {
  const { call, allIn } = useGameService();
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);

  const { playersStack } = useAppSelector(
    (state) => state.currentTable.gameState.pokerActions.betMultiplier
  );

  const { allIn: isAllIn, playerCallAmount: callAmount } = useAppSelector(
    (state) => state.currentTable.gameState.pokerActions
  );

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#5b6671',
            '&:hover': {
              color: 'white',
              backgroundColor: '#37393b'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  const onInternalClick = useCallback(() => {
    setIsActionDisabled(true);
    if (isAllIn) {
      allIn(playersStack);
    } else {
      call();
    }
  }, [isAllIn]);

  return (
    <ThemeProvider theme={DefaultBtn}>
      <Button size='small' onClick={onInternalClick} disabled={disabled}>
        {isAllIn
          ? `All In ${
              tableType === 'm' || tableType === 't'
                ? toLocaleStringNumber(playersStack)
                : currencyFormatter(playersStack)
            }`
          : `Call ${
              tableType === 'm' || tableType === 't'
                ? toLocaleStringNumber(callAmount)
                : currencyFormatter(callAmount)
            }`}
      </Button>
    </ThemeProvider>
  );
};

export default CallAction;
