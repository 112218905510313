import { useContext } from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GlobalContext from 'contexts/GlobalContext';
import { useAppSelector } from 'hooks';

const ErrorMsgModal = () => {
  const { openErrorModal, setOpenErrorModal } = useContext(GlobalContext);
  const errorMsg = useAppSelector((state) => state.user.errorMsg);

  const standUp = () => {
    setOpenErrorModal(false);
  };

  return (
    <Modal open={openErrorModal}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          Encountered an error!
        </Typography>

        <div
          style={{
            fontSize: '1.5rem',
            textAlign: 'center',
            marginBottom: '2rem'
          }}>
          {errorMsg.msg}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button sx={{ display: 'block', opacity: '.8' }} variant='contained' onClick={standUp}>
              OK
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default ErrorMsgModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
