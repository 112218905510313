import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import {
  Button,
  createTheme,
  FormControl,
  InputAdornment,
  Modal,
  OutlinedInput,
  ThemeProvider,
  Typography
} from '@mui/material';
import Slider from '@mui/material/Slider';
import { Box } from '@mui/system';
import GameContext from 'contexts/GameContext';
import GlobalContext from 'contexts/GlobalContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import { updateCurrentPlayerHasAddOnChips } from 'reducers/currentTable';
import useGameService from 'services/GameService';
import { calculateStepValue, numberFormatter } from 'utils/functions';

import CountDownTimer from 'components/Timer/CountDownTimer';

const AddChipsModal = () => {
  const game = useAppSelector((state) => state.currentTable.game);
  const { bb, maximumTopUp, languageKey, isPennyTable, tablelow: gameTablelow } = game;
  const { seatId, isSeated: isPlayerSeated } = useAppSelector(
    (state) => state.currentTable.currentPlayer
  );
  const { rebuy, leaveTable } = useGameService();

  const { openAddChips, isTimerOff, setOpenAddChips, setIsTimerOff } = useContext(GameContext);
  const { closeBuyInModal, setCloseBuyInModal } = useContext(GlobalContext);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const [addChipsAmount, setAddChipsAmount] = useState(0);
  const [curChipsFieldValue, setCurChipsFieldValue] = useState(0);
  const tablelow = gameTablelow > maximumTopUp ? maximumTopUp : gameTablelow;
  const half = (Number(maximumTopUp) / 2).toFixed(2);
  const dispatch = useDispatch();
  const currentPlayerHasAddOnChips = (game as any)[`p${seatId}hasAddOnChips`];

  const sliderStep = isPennyTable ? calculateStepValue(bb) : 1;

  const onComplete = () => {
    setCloseBuyInModal(true);
    setOpenAddChips(false);
    if (isPlayerSeated && players[seatId].stack < bb) {
      // leaveTable(false, true);
      // New requirement: stood up the player.
      // setTimeout(() => window.location.reload(), 100);
    }
  };

  useEffect(() => {
    setCurChipsFieldValue(addChipsAmount);
  }, [addChipsAmount]);

  const handleAddChipsSlider = (e: Event) => {
    setAddChipsAmount(Number((e?.target as any).value));
  };

  const handleAddChips = () => {
    // Track if player rebuys within the wait period or after.
    // If within wait period, set pstatus = 0, else, trigger returnTable.
    rebuy(seatId, addChipsAmount, languageKey === 'GAME_MSG_BUSTED_PAUSE' ? true : false);
    setOpenAddChips(false);
    setIsTimerOff(false);
    setAddChipsAmount(0);
    setCloseBuyInModal(false);
    dispatch(updateCurrentPlayerHasAddOnChips(seatId));
  };

  const cancelAddChips = () => {
    setAddChipsAmount(0);
    setOpenAddChips(false);

    if (languageKey === 'GAME_MSG_BUSTED_PAUSE' && isTimerOff) setCloseBuyInModal(true);
    else setCloseBuyInModal(false);

    if (
      languageKey === 'GAME_MSG_BUSTED_PAUSE' &&
      isTimerOff &&
      isPlayerSeated &&
      players[seatId].stack < bb
    ) {
      leaveTable(false, true);
      // New requirement: stood up the player.
      // setTimeout(() => window.location.reload(), 100);
    }
  };

  const handleAddChipsTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurChipsFieldValue((event?.target as any).value);

    if (validateAddChipsInput((event?.target as any).value)) {
      setAddChipsAmount((event?.target as any).value);
    } else {
      setAddChipsAmount(tablelow);
    }
  };

  const validateAddChipsInput = (value: string): boolean => {
    if (Number(value) > maximumTopUp) {
      setAddChipsAmount(maximumTopUp);
      return false;
    }
    return true;
  };

  const handlePasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = Number(event.clipboardData.getData('text'));
    setCurChipsFieldValue(pastedValue);
    setAddChipsAmount(pastedValue);
  };

  const addOnFormContent = (
    <>
      <Typography
        id='modal-modal-title'
        variant='h5'
        component='h2'
        sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        ADD CHIPS
      </Typography>

      {languageKey === 'GAME_MSG_BUSTED_PAUSE' && isTimerOff && (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '2rem'
            }}>
            <CountDownTimer isStartProgress={openAddChips} size={110} onComplete={onComplete} />
          </div>

          <div
            style={{
              fontSize: '1.5rem',
              textAlign: 'center',
              marginBottom: '2rem'
            }}>
            You ran out of chips. Add chips to rejoin the game.
          </div>
        </>
      )}

      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <ThemeProvider theme={BuyInPreSetBtn}>
          <Button
            onClick={() => setAddChipsAmount(maximumTopUp <= 0 ? 0 : +numberFormatter(tablelow))}>
            Min:
            <strong style={{ marginLeft: '0.25rem' }}>
              $
              {maximumTopUp <= 0
                ? 0
                : isPennyTable
                ? numberFormatter(tablelow)
                : numberFormatter(Math.floor(tablelow))}
            </strong>
          </Button>
          <Button
            onClick={() =>
              setAddChipsAmount(
                isPennyTable ? +half : Decimal.div(maximumTopUp, 2).floor().toNumber()
              )
            }
            disabled={
              ((isPennyTable ? +half : Decimal.div(maximumTopUp, 2).floor().toNumber()) <
                tablelow) as boolean
            }>
            1/2:
            <strong style={{ marginLeft: '0.25rem' }}>
              ${isPennyTable ? half : Decimal.div(maximumTopUp, 2).floor().toNumber()}
            </strong>
          </Button>
          <Button
            onClick={() =>
              setAddChipsAmount(
                isPennyTable
                  ? new Decimal(maximumTopUp).toDP(2).toNumber()
                  : Decimal.floor(maximumTopUp).toNumber()
              )
            }>
            Max:
            <strong style={{ marginLeft: '0.25rem' }}>
              $
              {isPennyTable
                ? new Decimal(maximumTopUp).toDP(2).toNumber()
                : Decimal.floor(maximumTopUp).toNumber()}
            </strong>
          </Button>
        </ThemeProvider>
      </div>

      <div style={{ padding: '0rem 0.5rem' }}>
        <Slider
          aria-label='BUY-IN'
          defaultValue={tablelow}
          onChange={(e) => handleAddChipsSlider(e)}
          max={+maximumTopUp}
          min={tablelow}
          sx={{ color: '#CC6B47' }}
          value={addChipsAmount}
          step={sliderStep}
        />
      </div>

      <div
        style={{
          marginTop: '0rem'
        }}>
        <ThemeProvider theme={TextFieldTheme}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <NumericFormat
              id='outlined-adornment-amount'
              customInput={OutlinedInput}
              value={addChipsAmount}
              onChange={handleAddChipsTextField}
              onPaste={handlePasteValue}
              decimalScale={isPennyTable ? 2 : 0}
              allowNegative={false}
              startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            />
          </FormControl>
        </ThemeProvider>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem',
          gap: '2rem'
        }}>
        <ThemeProvider theme={CancelBtnStyle}>
          <Button
            sx={{ display: 'block', opacity: '.8' }}
            variant='contained'
            onClick={cancelAddChips}>
            CANCEL
          </Button>
        </ThemeProvider>
        <ThemeProvider theme={AddChipsBtnStyle}>
          <Button
            sx={{ display: 'block' }}
            variant='contained'
            onClick={handleAddChips}
            disabled={
              addChipsAmount < tablelow ||
              maximumTopUp === 0 ||
              curChipsFieldValue > Number(maximumTopUp.toFixed(2))
            }>
            ADD CHIPS
          </Button>
        </ThemeProvider>
      </div>
    </>
  );

  const msgContent = (
    <>
      <div
        style={{
          fontSize: '1.5rem',
          textAlign: 'center',
          marginBottom: '2rem'
        }}>
        You are only allowed to add chips once during the hand
      </div>
      <ThemeProvider theme={CancelBtnStyle}>
        <Button
          sx={{ display: 'block', opacity: '.8' }}
          variant='contained'
          onClick={() => {
            setCloseBuyInModal(false);
            setOpenAddChips(false);
          }}>
          Close
        </Button>
      </ThemeProvider>
    </>
  );

  return (
    <Modal open={openAddChips && !closeBuyInModal}>
      <Box sx={modalStyle}>{currentPlayerHasAddOnChips ? msgContent : addOnFormContent}</Box>
    </Modal>
  );
};

export default AddChipsModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const TextFieldTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0.5rem 0rem !important',
          color: 'black'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundColor: 'white',
          fontSize: '1.5rem'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7) !important',
          fontSize: '1.5rem'
        }
      }
    }
  }
});

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const AddChipsBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const BuyInPreSetBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontWeight: '400',
          padding: '0.5rem 0.75rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
