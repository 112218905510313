import React from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import useGameService from 'services/GameService';

import 'components/UserActions/UserActions.scss';

interface CheckProps {
  disabled: boolean;
  setIsActionDisabled: (value: boolean) => void;
}

const CheckAction = ({ disabled, setIsActionDisabled }: CheckProps) => {
  const { check } = useGameService();

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#5b6671',
            '&:hover': {
              color: 'white',
              backgroundColor: '#37393b'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  return (
    // <div onClick={check} className="poker-action-btn">
    //   <div className="poker-action-content">
    //     <span className="text">Check</span>
    //   </div>
    // </div>
    <ThemeProvider theme={DefaultBtn}>
      <Button
        size='small'
        onClick={() => {
          setIsActionDisabled(true);
          check();
        }}
        disabled={disabled}>
        Check
      </Button>
    </ThemeProvider>
  );
};

export default CheckAction;
