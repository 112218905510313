import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { hiJackColors } from 'constants/colorConstants';
import GameContext from 'contexts/GameContext';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TableType, updateSelectedTournament } from 'reducers/lobby';
import useGameService from 'services/GameService';
import { currencyFormatter, getLocalTime } from 'utils/functions';

import TournamentDetails from 'components/Table/TournamentDetails';
import { TTournament } from 'components/TournamentTable/types';

enum observeMode {
  yes = 'yes',
  no = 'no'
}

enum ERegisterMode {
  Register = 'Register',
  UnRegister = 'Unregister'
}

// const CommingSoonTooltip = withStyles({
//   tooltip: {
//     color: hiJackColors.white,
//     backgroundColor: hiJackColors.red + '!important'
//   }
// })(Tooltip);

enum TournamentStatus {
  Announced = 'A',
  Registering = 'R',
  Playing = 'P',
  Finished = 'F',
  Cancelled = 'C'
}

enum PlayerStatus {
  Registered = 'R',
  Playing = 'P',
  Finished = 'F'
}

const TournamentPanel = () => {
  const [openModalMsg, setOpenModalMsg] = useState(false);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [notifMsg, setNotifMsg] = useState('');
  const [registerMode, setRegisterMode] = useState<ERegisterMode | null>(null);
  const [liveData, setLiveData] = useState<TTournament | null>(null);
  const [disableRegister, setDisableRegister] = useState(false);
  const [registerLabel, setRegisterLabel] = useState('Register');
  const { selectedTournament, tableTypeSelected, tournament } = useAppSelector(
    (state) => state.lobby
  );
  const serverTime = useAppSelector((state) => state.serverDetails.serverTime);
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const dispatch = useAppDispatch();
  const { unregister, register, tournamentRebuy, subscribeTournamentDetails } = useGameService();
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const guid = useAppSelector((state) => state.user.playerGUID);
  const [myStatus, setMyStatus] = useState('Status');
  const [myGameID, setMyGameID] = useState(-1);
  const [prevSelTournaId, setPrevSelTournaId] = useState(0);
  const [passTime, setPassTime] = useState(false);
  const [disableButtonColor, setDisableButtonColor] = useState(hiJackColors.darkGray);

  const { setShowTournamentDetails } = useContext(GameContext);

  // const disableObserve = true;

  const handleShowTournamentDetails = () => {
    setShowTournamentDetails(true);
    subscribeTournamentDetails(liveData?.tournamentId);
  };

  const handleCancel = () => {
    setOpenModalMsg(false);
  };

  const handleClose = () => {
    setOpenModalNotification(false);
  };

  const handleRegistration = () => {
    setOpenModalMsg(false);

    if (registerMode === ERegisterMode.Register) {
      if (myStatus === PlayerStatus.Finished && liveData?.status === TournamentStatus.Playing) {
        tournamentRebuy(seatId, liveData?.gameID, liveData?.tournamentId);
      } else {
        register(liveData?.tournamentId, liveData?.gameID);
      }
    } else {
      unregister(liveData?.tournamentId, liveData?.gameID);
    }

    setRegisterMode(null);
  };

  const getGameTypeLabel = () => {
    switch (tableTypeSelected) {
      case TableType.CashGames:
        return 'Cash Game';
      case TableType.Standard:
        return 'Multi-Table';
      case TableType.SitNGo:
        return 'Sit & Go';
      default:
        return '';
    }
  };

  const gameType = getGameTypeLabel();

  const registerMsg = useCallback(() => {
    const conditionCharge = liveData?.buyIn !== undefined ? liveData?.buyIn : 0;
    if (registerMode === ERegisterMode.Register) {
      return `You are about to ${registerMode} to ${gameType}. Your account will be charged: $ ${conditionCharge}`;
    } else {
      return `You are about to ${registerMode} to ${gameType}.`;
    }
  }, [gameType, registerMode]);

  useEffect(() => {
    if (liveData?.playersRegistered !== null) {
      if (liveData?.playersRegistered[guid] !== undefined) {
        const myDetails = liveData?.playersRegistered[guid].split(',');
        setMyGameID(myDetails[0]);
        setMyStatus(myDetails[1]);
        setPrevSelTournaId(+liveData?.tournamentId);

        if (Number(liveData?.serverTime) >= Number(liveData?.startAtTime)) {
          setPassTime(true);
        } else {
          setPassTime(false);
        }
      } else {
        setMyGameID(-1);
        setMyStatus('');
      }
    } else {
      setMyGameID(-1);
      setMyStatus('');
    }
  }, [serverTime]);

  useEffect(() => {
    if (liveData?.playersRegistered[guid] !== undefined) {
      const myDetails = liveData?.playersRegistered[guid].split(',');
      if (
        (myDetails[1] === PlayerStatus.Registered ||
          myDetails[1] === PlayerStatus.Playing ||
          myDetails[1] === PlayerStatus.Finished) &&
        (liveData?.status === TournamentStatus.Registering ||
          liveData?.status === TournamentStatus.Cancelled) &&
        passTime
      ) {
        setOpenModalNotification(true);

        if (liveData?.status === TournamentStatus.Registering && passTime) {
          setNotifMsg(
            `Registered players for tournament ${liveData?.tablename}, have not met the minimum. Waiting for additional players.`
          );
        } else if (liveData?.status === TournamentStatus.Cancelled) {
          setNotifMsg(
            `Tournament - ${liveData?.tablename} has been cancelled. Your refund will be processed by our teams as soon as possible. Please notify the Hijack Support if you do not receive your refund in the next 24 hours.`
          );
        }
      }
    }
  }, [liveData?.status, myStatus, passTime]);

  useEffect(() => {
    let currentData;

    switch (tableTypeSelected) {
      case TableType.Standard:
        currentData = tournament.tournamentList.find(
          (data: TTournament) => data.gameID === selectedTournament?.gameID
        );
        break;
      case TableType.SitNGo:
        currentData = tournament.sitAndGoList.find(
          (data: TTournament) => data.gameID === selectedTournament?.gameID
        );
        break;
      default:
        currentData = null;
    }

    if (currentData) {
      setLiveData(currentData);
    } else {
      dispatch(updateSelectedTournament(null));
    }
  }, [tournament.tournamentList, tournament.sitAndGoList, selectedTournament?.gameID]);

  useEffect(() => {
    let isQualified = false;
    if (liveData?.qualifiedPlayers !== null) {
      if (liveData?.qualifiedPlayers[guid] !== undefined) {
        isQualified = true;
      } else {
        isQualified = false;
      }
    } else {
      isQualified = false;
    }

    if (
      liveData?.lateEntry &&
      liveData?.status === TournamentStatus.Playing &&
      +serverTime > +liveData?.startAtTime
    ) {
      setDisableRegister(
        !(liveData?.lateEntryExpireMins >= liveData?.currentLevel) ||
          (liveData?.isMultiFlight && liveData?.isFinalMultiFlight) ||
          isMaintenance ||
          (liveData?.multiBagOption === 'none' && isQualified)
      );
      if (myStatus === PlayerStatus.Finished && liveData?.status === TournamentStatus.Playing) {
        setDisableRegister(false || isMaintenance);
        setRegisterLabel('Re-entry');
      } else if (
        prevSelTournaId === +liveData?.tournamentId &&
        myStatus === PlayerStatus.Registered &&
        liveData?.status === TournamentStatus.Playing &&
        +myGameID < 0
      ) {
        setDisableRegister(true);
        setDisableButtonColor(hiJackColors.deepMaroon);
        setRegisterLabel('Looking for table.');
      } else {
        setRegisterLabel('Late Register');
      }
    } else {
      if (liveData) {
        if (liveData.tabletype === 'm') {
          setDisableRegister(
            ((+serverTime > +liveData?.startAtTime ||
              liveData?.status === TournamentStatus.Announced) &&
              (liveData?.status !== TournamentStatus.Registering ||
                (liveData?.isMultiFlight && liveData?.isFinalMultiFlight) ||
                (liveData?.multiBagOption === 'none' && isQualified))) ||
              isMaintenance
          );
        } else {
          setDisableRegister(liveData?.status !== TournamentStatus.Registering || isMaintenance);
        }
      }
      setRegisterLabel('Register');
    }
  }, [serverTime, liveData]);

  const isTournament =
    tableTypeSelected === 'Standard' ||
    tableTypeSelected === 'Bounty' ||
    tableTypeSelected === 'Freezeout';
  const totalPlayersSitAndGo =
    liveData?.seatsTaken !== undefined && liveData?.maxPlayers !== undefined
      ? liveData.maxPlayers - liveData.seatsTaken
      : 0;

  const TournamentMsg = useCallback(() => {
    let message = '';
    const tournamentStatus = liveData?.status?.toLowerCase();

    switch (true) {
      case liveData?.isFinalMultiFlight &&
        tournamentStatus === TournamentStatus.Announced.toLowerCase():
        message = `Main event starts on ${getLocalTime(Number(liveData?.startAtTime))}`;
        break;

      case tournamentStatus === TournamentStatus.Announced.toLowerCase():
        message = `Registration starts on ${getLocalTime(
          Number(Number(liveData?.startAtTime) - Number(liveData?.registrationOpens) * 60)
        )}`;
        break;

      case tournamentStatus === TournamentStatus.Registering.toLowerCase():
        message = `Game starts on ${getLocalTime(Number(liveData?.startAtTime))}`;
        break;

      case tournamentStatus === TournamentStatus.Cancelled.toLowerCase():
        message = `Tournament has been cancelled`;
        break;

      default:
        message = '';
    }

    return message;
  }, [serverTime, liveData?.status]);

  const showJoinTable =
    (myStatus === TournamentStatus.Playing &&
      liveData?.closed === 0 &&
      liveData?.status !== TournamentStatus.Cancelled &&
      !liveData?.isMultiFlightBreak) ||
    (liveData?.isFinalMultiFlight &&
      myStatus === TournamentStatus.Playing &&
      !liveData?.isMultiFlightBreak) ||
    (myStatus === PlayerStatus.Registered && liveData?.gameType === TableType.SitNGo);

  const [flightsExpanded, setflightsExpanded] = useState(true);
  const hasSchedules = !!liveData?.tournamentFlightSchedules?.length;

  const handleChangeFlights = () => {
    setflightsExpanded(!flightsExpanded);
  };

  function flightScheduleStatus(currentFlight: number, seqId: number, tournamentStatus: string) {
    if (currentFlight === seqId && tournamentStatus === 'P') {
      return 'In Progress';
    } else if (currentFlight > seqId) {
      return 'Completed';
    } else {
      return 'On-time';
    }
  }

  function createData(
    id: number,
    flight: string,
    schedule: string,
    buyin: string,
    status: string,
    isFinal: number
  ) {
    return { id, flight, schedule, buyin, status, isFinal };
  }

  // const flightSchedules = [
  //   createData(1, '1A', 'Sep. 1, 11:30 AM', '$10.00', 'Completed'),
  //   createData(2, '1B', 'Sep. 1, 1:30 PM', '$10.00', 'In Progress'),
  //   createData(3, '1C', 'Sep. 1, 3:30 PM', '$10.00', 'On-time'),
  //   createData(4, '1D', 'Sep. 1, 5:30 PM', '$10.00', 'On-time'),
  //   createData(5, '1E', 'Sep. 1, 7:30 PM', '$10.00', 'On-time'),
  //   createData(6, 'Main', 'Sep. 1, 9:30 PM', '$10.00', 'On-time')
  // ];

  const flightSchedules = liveData?.tournamentFlightSchedules?.map((schedule, index) => {
    return createData(
      index,
      schedule.multiFlightName,
      schedule.multiFlightSchedule,
      liveData?.buyIn && currencyFormatter(parseFloat(liveData?.buyIn)),
      flightScheduleStatus(liveData?.currentFlight, schedule.seqId, liveData?.status),
      schedule.isFinal
    );
  });

  const renderFlightStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return (
          <Chip
            label='Completed'
            size='small'
            sx={{ backgroundColor: '#323546', color: '#FFFFFF', fontSize: '11px' }}
          />
        );
      case 'In Progress':
        return (
          <Chip
            label='In Progress'
            size='small'
            sx={{ backgroundColor: '#628B48', color: '#FFFFFF', fontSize: '11px' }}
          />
        );
      case 'On-time':
        return (
          <Chip
            label='On-time'
            size='small'
            sx={{ backgroundColor: '#E98C26', color: '#FFFFFF', fontSize: '11px' }}
          />
        );
    }
  };

  return (
    <>
      <MainPanel elevation={1}>
        <Header>
          <LabelStyle>{liveData?.tablename}</LabelStyle>
        </Header>
        <ContentWrapper>
          <ContentHeaderWrapper>
            {liveData?.status !== TournamentStatus.Cancelled && (
              <TimeWrapper>
                {tableTypeSelected === TableType.SitNGo
                  ? liveData?.status === TournamentStatus.Playing
                    ? liveData?.strStartsIn
                    : ''
                  : liveData?.status === TournamentStatus.Announced && !liveData?.isFinalMultiFlight
                  ? liveData?.strRegistrationIn
                  : liveData?.strStartsIn}
              </TimeWrapper>
            )}
            {tableTypeSelected === TableType.SitNGo && (
              <TimeWrapper style={{ fontFamily: 'Outfit' }}>{'Sit and Go'}</TimeWrapper>
            )}
            {tableTypeSelected === TableType.Standard &&
              liveData?.strRegistrationTime &&
              liveData?.status !== TournamentStatus.Playing && (
                <SubTimeWrapper>{TournamentMsg()}</SubTimeWrapper>
              )}

            {tableTypeSelected === TableType.SitNGo && totalPlayersSitAndGo !== 0 && (
              <SubTimeWrapper>
                {`${totalPlayersSitAndGo} ${
                  totalPlayersSitAndGo === 1 ? 'player' : 'more players'
                } to start the game`}
              </SubTimeWrapper>
            )}
            <GameWrapper>
              <DetailLabel>
                {`${liveData?.gameType}, ${
                  liveData?.maxPlayers === 0 ? 'No Limit' : liveData?.maxPlayers + ' Max'
                } `}
              </DetailLabel>
              <SubDetailLabel>Game Type</SubDetailLabel>
            </GameWrapper>
            {liveData?.isSatellite ? (
              <SatelliteWrapper>
                <BtnSatellite>Satellite</BtnSatellite>
                <SatelliteDetailWrapper>
                  <SatelliteDetailTitleLabel>
                    {`${liveData?.tablename} @ ${liveData?.satelliteAt}`}
                  </SatelliteDetailTitleLabel>
                  <br />
                  <SatelliteDetailLabel>{`${liveData?.satelliteDescription} - ${getLocalTime(Number(liveData?.satelliteLiveSchedule))}`}</SatelliteDetailLabel>
                </SatelliteDetailWrapper>
              </SatelliteWrapper>
            ) : ''}
          </ContentHeaderWrapper>
          <DetailWrapper>
            <RowColWrapper>
              <SubDetailWrapper>
                <DetailLabel>
                  {liveData?.prizePool && currencyFormatter(liveData?.prizePool)}
                </DetailLabel>
                <SubDetailLabel>Current Prize Pool</SubDetailLabel>
              </SubDetailWrapper>
              <SubDetailWrapper>
                <DetailLabel>
                  {liveData?.lvlDisplay === 0 ? 'Break' : liveData?.lvlDisplay}
                </DetailLabel>
                <SubDetailLabel>Level</SubDetailLabel>
              </SubDetailWrapper>
              {isTournament && liveData?.addOn ? (
                <SubDetailWrapper>
                  <DetailLabel>{liveData?.addOn}</DetailLabel>
                  <SubDetailLabel>Add On</SubDetailLabel>
                </SubDetailWrapper>
              ) : (
                ''
              )}
              {liveData?.isSatellite ? (
                <SubDetailWrapper>
                  <DetailLabel>{liveData?.satelliteSeatValue} {+liveData?.satelliteSeatValue > 1 ? 'Seats' : 'Seat' }</DetailLabel>
                  <SubDetailLabel>Guarantee</SubDetailLabel>
                </SubDetailWrapper>
              ) : ''}
            </RowColWrapper>
            <RowColWrapper>
              <SubDetailWrapper>
                <DetailLabel>
                  {liveData?.buyIn && currencyFormatter(parseFloat(liveData?.buyIn))}
                </DetailLabel>
                <SubDetailLabel>Buy In</SubDetailLabel>
              </SubDetailWrapper>
              <SubDetailWrapper>
                <DetailLabel>{liveData?.startingChips}</DetailLabel>
                <SubDetailLabel>Starting Chips</SubDetailLabel>
              </SubDetailWrapper>
              {liveData?.gameType === TableType.Bounty && (
                <SubDetailWrapper>
                  <DetailLabel>
                    {liveData?.bountyAmount && currencyFormatter(liveData?.bountyAmount)}
                  </DetailLabel>
                  <SubDetailLabel>Bounty Amount</SubDetailLabel>
                </SubDetailWrapper>
              )}
              {isTournament && liveData?.addOn ? (
                <SubDetailWrapper>
                  <DetailLabel>{currencyFormatter(liveData?.addOnPrice ?? 0)}</DetailLabel>
                  <SubDetailLabel>Add On Fee</SubDetailLabel>
                </SubDetailWrapper>
              ) : (
                ''
              )}
            </RowColWrapper>
            <RowColWrapper>
              {isTournament ? (
                <SubDetailWrapper>
                  <DetailLabel
                    style={{
                      fontSize: '16px',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                    {liveData?.rebuys === 0 ? 'NA' : liveData?.rebuys}
                    {liveData?.lateEntry !== 0 && (
                      <span style={smallFontSizeStyle}>(During Late Registration)</span>
                    )}
                  </DetailLabel>
                  <SubDetailLabel>Re-entry</SubDetailLabel>
                </SubDetailWrapper>
              ) : (
                <></>
                // <SubDetailWrapper>
                //   <DetailLabel
                //     style={{
                //       color: hiJackColors.orange,
                //       fontSize: 'smaller',
                //       whiteSpace: 'nowrap',
                //       overflow: 'hidden',
                //       textOverflow: 'ellipsis'
                //     }}>
                //     {liveData?.tablename && liveData?.tablename}
                //   </DetailLabel>
                //   <SubDetailLabel>
                //     <span className='g-font-outlined'>satellite_alt</span> Satellite To
                //   </SubDetailLabel>
                // </SubDetailWrapper>
              )}
              {isTournament && (
                <></>
                // <SubDetailWrapper>
                //   <DetailLabel
                //     style={{
                //       color: hiJackColors.orange,
                //       fontSize: 'smaller',
                //       whiteSpace: 'nowrap',
                //       overflow: 'hidden',
                //       textOverflow: 'ellipsis',
                //       width: '100px'
                //     }}>
                //     {liveData?.tablename && liveData?.tablename}
                //   </DetailLabel>
                //   <SubDetailLabel>
                //     <span className='g-font-outlined'>satellite_alt</span> Satellite To
                //   </SubDetailLabel>
                // </SubDetailWrapper>
              )}
              <SubDetailWrapper>
                <DetailLabel>{liveData?.maxLevel}</DetailLabel>
                <SubDetailLabel>No. of Levels</SubDetailLabel>
              </SubDetailWrapper>
              {isTournament && liveData?.addOn ? (
                <SubDetailWrapper>
                  <DetailLabel>{liveData?.addOnChips}</DetailLabel>
                  <SubDetailLabel>Add On Chips</SubDetailLabel>
                </SubDetailWrapper>
              ) : (
                ''
              )}
            </RowColWrapper>
          </DetailWrapper>
          {hasSchedules && liveData?.isMultiFlight ? (
            <FlightsWrapper>
              <Grid
                container
                justifyContent={{
                  xs: 'center'
                }}>
                <Grid item xs={12}>
                  <Accordion expanded={flightsExpanded} onChange={handleChangeFlights}>
                    <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                      <span style={{ color: hiJackColors.orange, textAlign: 'center' }}>
                        <span>{flightsExpanded ? 'Hide' : 'Show'}</span> Flight Schedules
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Paper
                        style={{
                          borderRadius: 0,
                          overflow: 'hidden',
                          backgroundColor: 'transparent'
                        }}>
                        <Table sx={{ marginBottom: '0px' }}>
                          <TableHead>
                            <StyledHeaderRow>
                              <StyledHeaderCell>Flight</StyledHeaderCell>
                              <StyledHeaderCell>Schedule</StyledHeaderCell>
                              <StyledHeaderCell>Buy-in</StyledHeaderCell>
                              <StyledHeaderCell>Status</StyledHeaderCell>
                            </StyledHeaderRow>
                          </TableHead>
                        </Table>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              {flightSchedules?.map((flightSchedule) => (
                                <StyledRow key={flightSchedule.id}>
                                  <StyledCell>{flightSchedule.flight}</StyledCell>
                                  <StyledCell sx={{ fontSize: '11px', paddingX: '4px' }}>
                                    {getLocalTime(Number(flightSchedule.schedule))}
                                  </StyledCell>
                                  <StyledCell>
                                    {flightSchedule.isFinal === 0 ? flightSchedule.buyin : '-'}
                                  </StyledCell>
                                  <StyledCell>
                                    {renderFlightStatus(flightSchedule.status)}
                                  </StyledCell>
                                </StyledRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </FlightsWrapper>
          ) : (
            <></>
          )}
          {liveData?.status !== 'C' && (
            <RowWrapper
              style={{
                justifyContent: 'center',
                columnGap: 8,
                height: '100%',
                paddingBottom: '24px'
              }}>
              {liveData?.gameType !== TableType.SitNGo &&
              myStatus === PlayerStatus.Registered &&
              liveData?.status === TournamentStatus.Registering &&
              !liveData?.isMultiFlightBreak ? (
                <BtnRegister
                  style={{ opacity: disableRegister ? '.4' : '1' }}
                  onClick={() => {
                    setOpenModalMsg(true), setRegisterMode(ERegisterMode.UnRegister);
                  }}
                  disabled={isMaintenance || liveData?.isFinalMultiFlight ? true : false}>
                  Unregister
                </BtnRegister>
              ) : showJoinTable ? (
                <Link
                  style={{ textDecoration: 'none', color: '#FFFFFF' }}
                  to={
                    isMaintenance
                      ? '#'
                      : `/game/${
                          liveData?.gameType === TableType.SitNGo ? liveData?.gameID : myGameID
                        }/type/${liveData.game}?observe=${observeMode.no}`
                  }
                  target={isMaintenance ? '' : '_blank'}
                  rel='noopener'>
                  <BtnJoin
                    disabled={!showJoinTable}
                    style={{ opacity: isMaintenance ? '.4' : '1' }}>
                    Join
                  </BtnJoin>
                </Link>
              ) : (
                <BtnRegister
                  style={{
                    opacity: '1',
                    backgroundColor: disableRegister ? disableButtonColor : hiJackColors.deepMaroon
                  }}
                  onClick={() => {
                    setOpenModalMsg(true), setRegisterMode(ERegisterMode.Register);
                  }}
                  disabled={disableRegister}>
                  {registerLabel}
                </BtnRegister>
              )}
              {liveData?.gameType !== TableType.SitNGo && (
                <BtnTournamentInfo
                  disabled={isMaintenance}
                  onClick={() => handleShowTournamentDetails()}>
                  Tournament Info
                </BtnTournamentInfo>
              )}
            </RowWrapper>
          )}
        </ContentWrapper>
        <Dialog
          open={openModalMsg}
          onClose={handleCancel}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          PaperComponent={StyledPaper}>
          <BoxStyle>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent style={{ color: hiJackColors.white }}>
              <DialogContentText style={{ color: hiJackColors.white }}>
                {registerMsg()}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BtnProceed onClick={handleRegistration}>Proceed</BtnProceed>
              <BtnCancel onClick={handleCancel}>Cancel</BtnCancel>
            </DialogActions>
          </BoxStyle>
        </Dialog>
        <Dialog
          open={openModalNotification}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          PaperComponent={StyledPaper}>
          <BoxStyle>
            <DialogTitle>Notification</DialogTitle>
            <DialogContent style={{ color: hiJackColors.white }}>
              <DialogContentText style={{ color: hiJackColors.white, textAlign: 'center' }}>
                {notifMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BtnCancel onClick={handleClose}>Close</BtnCancel>
            </DialogActions>
          </BoxStyle>
        </Dialog>
      </MainPanel>
      {selectedTournament?.status !== 'C' && (
        <TournamentDetails
          tournamentId={+(selectedTournament?.tournamentId ?? '0')}
          gameTitle={selectedTournament?.tablename ?? ''}
          tableType={selectedTournament?.tabletype ?? ''}
          tournamentPrizePool={selectedTournament?.prizePool ?? 0}
          tournamentStatus={selectedTournament?.status ?? ''}
        />
      )}
    </>
  );
};

const StyledPaper = styled(Paper)`
  background-color: ${hiJackColors.darkJungleGreen};
`;

const BoxStyle = styled(Box)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.darkJungleGreen,
  borderRadius: 8
});

const BtnJoin = styled(Button)({
  width: '140px',
  height: '35px',
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const BtnProceed = styled(Button)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const BtnTournamentInfo = styled(Button)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const BtnCancel = styled(Button)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.darkGray,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

// const BtnObserveCommingSoon = styled(Box)({
//   width: '140px',
//   height: '35px',
//   color: hiJackColors.mediumLightGray,
//   backgroundColor: hiJackColors.darkGray,
//   fontSize: '12px',
//   fontWeight: 600,
//   fontFamily: 'Outfit',
//   textTransform: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   flexWrap: 'nowrap',
//   alignItems: 'center',
//   justifyContent: 'center',
//   borderRadius: '4px',
//   '&:hover, &:focus': {
//     backgroundColor: hiJackColors.darkGray,
//     cursor: 'not-allowed'
//   },
//   opacity: '1'
// });

const smallFontSizeStyle = {
  fontSize: '12px',
  whiteSpace: 'nowrap'
} as const;

const BtnSatellite = styled(Button)({
  width: '100px',
  height: '30px',
  position: 'absolute',
  color: hiJackColors.sunflower,
  backgroundColor: hiJackColors.darkGray,
  fontSize: '12px',
  marginTop: '0.6rem',
  fontWeight: 600,
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.darkGray
  },
  borderRadius:'15px',
  lineHeight: '1'
});

const BtnRegister = styled(Button)({
  width: '140px',
  height: '35px',
  color: hiJackColors.white,
  backgroundColor: hiJackColors.deepMaroon,
  fontSize: '12px',
  fontWeight: 600,
  textDecoration: 'none',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: hiJackColors.red
  }
});

const LabelStyle = styled('label')({
  textAlign: 'center',
  color: hiJackColors.orange,
  fontSize: '26px',
  fontWeight: 700,
  fontFamily: 'Outfit'
});

const DetailLabel = styled('label')({
  textAlign: 'center',
  color: hiJackColors.white,
  fontWeight: 700,
  fontSize: '16px',
  fontFamily: 'Outfit'
});

const SatelliteDetailTitleLabel = styled('label')({
  textAlign: 'left',
  color: hiJackColors.darkGray,
  fontWeight: 700,
  fontSize: '12px',
  fontFamily: 'Outfit'
});

const SatelliteDetailLabel = styled('label')({
  textAlign: 'left',
  color: hiJackColors.darkGray,
  fontSize: '12px',
  fontFamily: 'Outfit',
  overflowWrap: 'anywhere'
});

const SubDetailLabel = styled('label')({
  textAlign: 'center',
  color: hiJackColors.white,
  fontSize: '12px',
  fontFamily: 'Outfit'
});

const MainPanel = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: hiJackColors.darkSlateGray,
  width: '100%',
  borderRadius: '8px'
});

const Header = styled(Box)({
  width: '100%',
  height: '60px',
  padding: '8px',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center'
});

const RowWrapper = styled(Box)({
  width: '100%',
  height: '40px',
  padding: '8px',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontFamily: 'Outfit'
});

const RowColWrapper = styled(Box)({
  width: '100%',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '5px'
});

const SubDetailWrapper = styled(Box)({
  height: '60px',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontFamily: 'Outfit'
});

const ContentHeaderWrapper = styled(Box)({
  width: '100%',
  padding: '8px',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Outfit'
});

const TimeWrapper = styled(Box)({
  width: '100%',
  fontSize: '30px',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Seven Segment',
  fontWeight: 700
});

const SubTimeWrapper = styled(Box)({
  width: '100%',
  height: '40px',
  padding: '8px',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Outfit',
  fontWeight: 700
});

const GameWrapper = styled(Box)({
  width: '100%',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Outfit'
});

const SatelliteDetailWrapper = styled(Box)({
  marginLeft: '7rem'
});

const SatelliteWrapper = styled(Box)({
  width: '100%',
  fontSize: '1rem',
  color: hiJackColors.white,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'left',
  fontFamily: 'Outfit',
  backgroundColor: hiJackColors.sunflower,
  padding: '10px',
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '10px',
  overflow: 'auto',
  height: '100%',
  '&::-webkit-scrollbar': {
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: hiJackColors.mediumGray
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: hiJackColors.mediumLightGray
  },
  alignItems: 'center'
});

const DetailWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

const FlightsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <span className='g-font-outlined' style={{ color: hiJackColors.orange }}>
        expand_more
      </span>
    }
    {...props}
  />
))(() => ({
  backgroundColor: 'transparent',
  textAlign: 'center',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    flexGrow: 0,
    marginRight: 8
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    display: 'flex'
  }
}));

const StyledHeaderCell = styled(TableCell)({
  width: 100,
  color: 'white',
  textAlign: 'center'
});

const StyledCell = styled(TableCell)({
  width: 100,
  textAlign: 'center'
});

const StyledHeaderRow = styled(TableRow)({
  backgroundColor: 'black',
  '& .MuiTableCell-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
    border: 'none',
    fontWeight: 'bold'
  },
  '& .MuiTableCell-root:first-child': {
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '0px'
  },
  '& .MuiTableCell-root:last-child': {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '0px'
  }
});

const StyledRow = styled(TableRow)({
  backgroundColor: '#161B1F',
  marginBottom: '1px',
  '& .MuiTableCell-root': {
    paddingTop: '8px',
    paddingBottom: '8px',
    border: 'none',
    color: 'white'
  },
  '& .MuiTableRow-root:first-child .MuiTableCell-root:first-child': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  '& .MuiTableRow-root:first-child .MuiTableCell-root:last-child': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  }
});

export default TournamentPanel;
