import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

import AccessTokenExpiredMessageModal from './components/AccessTokenExpiredMessageModal';
import MobileRedirect from './components/MobileRedirect/MobileRedirect';
import NotFound from './components/NotFound/NotFound';
import {
  AVATAR_CHANGE_RESPONSE,
  CREATE_PLAYER_RESPONSE,
  FEATURE_FLAGS,
  GOT_CHIPS_ADDED,
  GOT_DEVICE_CONNECTED,
  GOT_DUPLICATE_ACCESS,
  GOT_JOIN_GAME,
  GOT_LIVE_CHAT_NOTIFICATION,
  GOT_LOCATION,
  GOT_LOGIN,
  GOT_MAINTENANCE_SCHEDULE,
  GOT_OMAHA,
  GOT_PLAYER_GAMES,
  GOT_PLAYER_MONEY,
  GOT_RANK,
  GOT_REFRESH_TOKEN,
  GOT_SERVER_TIME,
  GOT_TEXAS,
  GOT_TOURNAMENT_STATUS,
  INVALID_TOKEN
} from './constants/eventConstants';
import { ALLOWED_BROWSER_MINIMUM_SCREEN_SIZE } from './constants/gameConstants';
import { GameProvider } from './contexts/GameContext';
import GlobalContext from './contexts/GlobalContext';
import LobbyContext from './contexts/LobbyContext';
import { ScaleContextProvider } from './contexts/ScaleContext';
import { SocketContext } from './contexts/SocketContext';
import Game from './pages/Game';
import Lobby from './pages/Lobby/Lobby';
import {
  updateCurrentPlayerBankAmount,
  updateCurrentPlayerGUID,
  updateCurrentTable,
  updateTournamentStatus
} from './reducers/currentTable';
import { updateLiveChatNotifications } from './reducers/liveChatNotifications';
import { setMaintenanceSchedule } from './reducers/maintenanceSchedule';
import { updatePlayerGames } from './reducers/playerGames';
import { setFeatureFlags, setServerDetails } from './reducers/serverDetails';
import {
  updateAvatar,
  updateBankAmount,
  updateErrorMsg,
  updateHandRank,
  updateUser
} from './reducers/user';
import useAuthService from './services/AuthService';
import useGameService from './services/GameService';
import { decompressData } from './utils/functions';
import { APP_DOMAIN, DESCOPE_COOKIE, FRONT_OFFICE_LOCAL_URL } from './utils/urls';
import { useAppDispatch, useAppSelector } from './hooks';

import './App.scss';

// Sentry.init({
//   dsn: 'https://d561771c534d4861a1fd17297598a0b6@o4504287409602560.ingest.sentry.io/4504877076905984',
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [
//     new BrowserTracing(),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//       maskAllInputs: false
//     })
//   ],
//   tracesSampleRate: 1.0,
//   environment: process.env.REACT_APP_ENVIRONMENT || 'localhost',
//   release: '1.0',
//   debug: true,
//   enabled: true
// });

function App() {
  const { setIsLogged } = useContext(LobbyContext);
  const socket = useContext(SocketContext);
  const {
    // isLocationInvalid,
    setOpenAccessTokenModal,
    setIsLocated,
    setIsLocationInvalid,
    setDeviceId,
    setDuplicateAccessMsg,
    setIsDuplicateAccess,
    setOpenErrorModal
  } = useContext(GlobalContext);
  const playerEmail = useAppSelector((state) => state.user.playerEmail);
  const dispatch = useAppDispatch();
  const authService = useAuthService();
  const gameService = useGameService();
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const game = useAppSelector((state) => state.currentTable.game);

  useEffect(() => {
    Sentry.setUser({ email: playerEmail });
    authService.createPlayer();

    socket.on('connect_error', (error) => {
      Sentry.captureException(error);
    });

    socket.on('connect_timeout', (error) => {
      Sentry.captureException(error);
    });

    socket.on('error', (error) => {
      Sentry.captureException(error);
    });

    socket.on('disconnect', (error) => {
      Sentry.captureException(error);
    });

    socket.on('reconnect_error', (error) => {
      Sentry.captureException(error);
    });

    socket.on('reconnect_failed', (error) => {
      Sentry.captureException(error);
    });

    return () => {
      socket.off('connect_error');
      socket.off('connect_timeout');
      socket.off('error');
      socket.off('disconnect');
      socket.off('reconnect_error');
      socket.off('reconnect_failed');
    };
  }, [playerEmail, socket]);

  const matches = useMediaQuery(`(max-width:${ALLOWED_BROWSER_MINIMUM_SCREEN_SIZE}px)`);

  // useEffect(() => {
  //   if (isLocationInvalid) socket.disconnect();
  // }, [isLocationInvalid]);

  useEffect(() => {
    socket.on(GOT_TEXAS, (data) => {
      dispatch(updateCurrentTable(decompressData(data)));
    });

    socket.on(GOT_OMAHA, (data) => {
      dispatch(updateCurrentTable(decompressData(data)));
    });

    socket.on(GOT_PLAYER_MONEY, (data: any) => {
      dispatch(updateBankAmount(decompressData(data)));
      dispatch(updateCurrentPlayerBankAmount(decompressData(data)));
    });

    socket.on(GOT_LOCATION, (data: any) => {
      data = decompressData(data);
      if (data.status === 'success') setIsLocated(true);
      else if (data.status === 'error') {
        setIsLocationInvalid(true);
      }
    });

    socket.on(GOT_DEVICE_CONNECTED, (data: any) => {
      data = decompressData(data);
      document.cookie = `_socketuuid=${data}`;
      setDeviceId(data);
    });

    socket.on(GOT_DUPLICATE_ACCESS, (data: any) => {
      data = decompressData(data);
      setDuplicateAccessMsg(data.msg);
      setIsDuplicateAccess(data.isDuplicate);
    });

    socket.on(GOT_LOGIN, (data: any) => {
      data = decompressData(data);
      const hostnameSegments = window.location.hostname.split('.');
      let login_redirect = `https://account.${hostnameSegments[1]}.${hostnameSegments[2]}/login`;

      if (window.location.hostname === 'hijack.local') {
        login_redirect = `${FRONT_OFFICE_LOCAL_URL}/login`;
      }

      if (
        data?.response?.error?.startsWith('Account not found') ||
        data?.response?.playerName === 'NOUSER'
      ) {
        window.location.href = login_redirect;
      } else {
        dispatch(updateUser(data));
        dispatch(updateCurrentPlayerGUID(data?.response?.playerGUID));

        localStorage.setItem('playerGUID', data?.response?.playerGUID);
        localStorage.setItem('playerName', data?.response?.playerName);
        localStorage.setItem('deviceID', data?.response?.deviceID);

        authService.online(data?.response?.playerGUID);
        authService.syncAvatar(data?.response?.playerAvatar);
        setIsLogged(true);
      }
    });

    socket.on(GOT_JOIN_GAME, (data: any) => {
      data = decompressData(data);
      if (data?.response?.error) {
        dispatch(
          updateErrorMsg({
            msg: data?.response?.error
          })
        );
        setOpenErrorModal(true);
      }
    });

    socket.on(CREATE_PLAYER_RESPONSE, (data: any) => {
      data = decompressData(data);
      authService.loginPlayer(data);
    });

    socket.on(AVATAR_CHANGE_RESPONSE, (data: any) => {
      data = decompressData(data);
      dispatch(updateAvatar(data));
    });

    socket.on(GOT_PLAYER_GAMES, (games) => {
      games = decompressData(games);
      dispatch(updatePlayerGames(games));
    });

    socket.on(GOT_LIVE_CHAT_NOTIFICATION, (liveChatNotifications) => {
      liveChatNotifications = decompressData(liveChatNotifications);
      dispatch(updateLiveChatNotifications(liveChatNotifications));
    });

    // socket.on(GOT_CHARGED, (data: any) => {
    // dispatch(updateCharged(data));

    // dispatch(
    //   addHistory([
    //     {
    //       msg: `${data.msg} - ${moment(data.time).local().format('hh:mm:ss A')}`,
    //       systemMsg: true,
    //       isShowCard: false,
    //       isCharge: true
    //     }
    //   ] as History[])
    // );

    // setTimeout(() => {
    //   dispatch(updateCharged(null));
    // }, 1000);
    // });

    // TO DO:
    // Turning this off, this is not doing anything as of the moment.
    // We can add this back once we have the online indicator / signal strength.
    // socket.on(GOT_HEARTBEAT, ( usr:any ) => {
    // console.log("GOT_HEARTBEAT", usr);
    // });

    socket.on(GOT_RANK, (data: any) => {
      data = decompressData(data);
      dispatch(updateHandRank(data?.rank));
    });

    socket.on(INVALID_TOKEN, () => {
      setOpenAccessTokenModal(true);
    });

    socket.on(GOT_MAINTENANCE_SCHEDULE, (data: any) => {
      data = decompressData(data);
      dispatch(setMaintenanceSchedule(data));
    });

    socket.on(GOT_SERVER_TIME, (data: any) => {
      data = decompressData(data);
      dispatch(setServerDetails(data));
    });

    socket.on(FEATURE_FLAGS, (data: any) => {
      data = decompressData(data);
      dispatch(setFeatureFlags(data));
    });

    socket.on(GOT_REFRESH_TOKEN, (refreshToken) => {
      refreshToken = decompressData(refreshToken);
      if (refreshToken?.error === 'invalid_grant') {
        window.location.href = `${FRONT_OFFICE_LOCAL_URL}/logout`;
      } else {
        Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken), { path: '/' });
        Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken) as any, {
          path: '/',
          domain: `.${APP_DOMAIN}`
        });
        Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken) as any, {
          path: '/',
          domain: `.${APP_DOMAIN}`
        });

        // Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken), { path: '/game' });
        // Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken) as any, {
        //   path: '/game',
        //   domain: `.${APP_DOMAIN}`
        // });
        // Cookies.set(DESCOPE_COOKIE as string, JSON.stringify(refreshToken) as any, {
        //   path: '/game',
        //   domain: `.${APP_DOMAIN}`
        // });

        socket.auth = {
          access_token: refreshToken.access_token,
          refresh_token: refreshToken.refresh_token
        };
        socket.connect();
      }
    });

    socket.on(GOT_TOURNAMENT_STATUS, (data: any) => {
      data = decompressData(data);
      dispatch(updateTournamentStatus(data));
    });

    window.addEventListener('unload', () => gameService.leaveTable(true));
    window.addEventListener('close', () => gameService.leaveTable(true));

    return () => {
      socket.off(GOT_TOURNAMENT_STATUS);
      socket.off(AVATAR_CHANGE_RESPONSE);
      socket.off(CREATE_PLAYER_RESPONSE);
      // socket.off(GOT_CHARGED);
      socket.off(GOT_CHIPS_ADDED);
      // socket.off(GOT_HEARTBEAT);
      socket.off(GOT_LOGIN);
      socket.off(GOT_JOIN_GAME);
      socket.off(GOT_PLAYER_GAMES);
      socket.off(GOT_PLAYER_MONEY);
      socket.off(GOT_RANK);
      socket.off(GOT_TEXAS);
      socket.off(GOT_OMAHA);
      socket.off(INVALID_TOKEN);
      socket.off(GOT_MAINTENANCE_SCHEDULE);
      socket.off(GOT_SERVER_TIME);
      socket.off(GOT_REFRESH_TOKEN);
      socket.off(GOT_DEVICE_CONNECTED);
      socket.off(GOT_DUPLICATE_ACCESS);

      window.removeEventListener('unload', () => gameService.leaveTable(true));
      window.removeEventListener('close', () => gameService.leaveTable(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMaintenance && (game?.hand === '' || game?.hand === '0') && game?.tabletype === 's') {
      gameService.leaveTable();
      setTimeout(() => window.close(), 1000);
    }
  }, [gameService, game?.hand, isMaintenance]);

  return (
    <>
      <CssBaseline />
      <Routes>
        <Route
          path='/'
          element={
            !matches ? (
              <GameProvider>
                <ScaleContextProvider>
                  <Lobby />
                </ScaleContextProvider>
              </GameProvider>
            ) : (
              <MobileRedirect />
            )
          }></Route>
        <Route
          path='/game/:gameID/type/:gameType'
          element={
            !matches ? (
              <GameProvider>
                <ScaleContextProvider>
                  <Game />
                </ScaleContextProvider>
              </GameProvider>
            ) : (
              <MobileRedirect />
            )
          }></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>

      <AccessTokenExpiredMessageModal />
    </>
  );
}

export default App;
