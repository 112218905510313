import React, { PropsWithChildren } from 'react';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  extractAccessTokenFromDescopeCookie,
  extractRefreshTokenFromDescopeCookie
} from 'utils/functions';
import { APP_DOMAIN, DESCOPE_COOKIE, SOCKET_SERVER_URL } from 'utils/urls';

const getAuthToken = () => {
  const descopeCookie = Cookies.get(DESCOPE_COOKIE as string);
  if (!descopeCookie || typeof descopeCookie !== 'string') {
    return {
      access_token: null,
      refresh_token: null
    };
  }
  return {
    access_token: extractAccessTokenFromDescopeCookie(descopeCookie),
    refresh_token: extractRefreshTokenFromDescopeCookie(descopeCookie)
  };
};

// // eslint-disable-next-line unused-imports/no-unused-vars
// const getSocket = () => {
//   const token = getAuthToken(); // get jwt token from local storage or cookie
//   if (token) {
//     return io(SOCKET_SERVER_URL, {
//       query: { token },
//       secure: false,
//       transports: ['websocket'],
//       forceNew: true,
//       reconnection: false
//     });
//   }
//   return io(SOCKET_SERVER_URL, {
//     secure: false,
//     transports: ['websocket'],
//     forceNew: true,
//     reconnection: false
//   });
// };

const token = getAuthToken(); // get jwt token from local storage or cookie
export const socket = io(SOCKET_SERVER_URL, {
  auth: { access_token: token.access_token, refresh_token: token.refresh_token },
  secure: false,
  transports: ['websocket'],
  // forceNew: true,
  reconnection: true,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 10000
});

// By default, auto reconnect is subject to a randomizationFactor and some other configurations that could affect the timing.
// This could further increase the delay of the reconnect. So, let's do this manually instead.
// Let's see if this will have an impact on our connections.

const reconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        reconnect();
      }
    });
  }, 1000);
};

socket.io.on('close', reconnect);

socket.on('disconnect', (reason) => {
  if (reason === 'io server disconnect') {
    // the disconnection was initiated by the server, you need to reconnect manually
    socket.connect();
  }
  // else the socket will automatically try to reconnect
});

socket.on('connect_error', (error) => {
  if (error.message.indexOf('Unauthorized') > -1) {
    Cookies.remove(DESCOPE_COOKIE as string, { path: '/' });
    Cookies.remove(DESCOPE_COOKIE as string, {
      path: '/',
      domain: `.${APP_DOMAIN}`
    });
    window.location.reload();
  } else {
    const newToken = getAuthToken(); // get jwt token from local storage or cookie
    socket.auth = { access_token: newToken.access_token, refresh_token: newToken.refresh_token };
    socket.connect();
  }
});

export const SocketContext = React.createContext(socket);

const SocketProvider = ({ children }: PropsWithChildren) => {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
