import React, { useCallback, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import GlobalContext from 'contexts/GlobalContext';
import { FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

const AccessTokenExpiredMessageModal = () => {
  const { openAccessTokenModal, setOpenAccessTokenModal } = useContext(GlobalContext);

  const closeAccessTokenModal = useCallback(() => {
    setOpenAccessTokenModal(false);
    window.location.href = `${FRONT_OFFICE_LOCAL_URL}/logout`;
  }, [setOpenAccessTokenModal]);

  return (
    <Dialog
      open={openAccessTokenModal}
      onClose={closeAccessTokenModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <DialogTitle id='alert-dialog-title'>Information</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Your token has expired. Please log in again to refresh token.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAccessTokenModal}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessTokenExpiredMessageModal;
