import { useContext, useEffect, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FaAngleDoubleRight } from 'react-icons/fa';
import { GiTrophy } from 'react-icons/gi';
import { RiVipCrownFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress, Divider } from '@mui/material';
import {
  GOT_TOURNAMENT_ACTIVE_TABLES,
  GOT_TOURNAMENT_PRIZES,
  GOT_TOURNAMENT_RANKING,
  GOT_TOURNAMENT_STRUCTURE
} from 'constants/eventConstants';
import GameContext from 'contexts/GameContext';
import ScaleContext from 'contexts/ScaleContext';
import { socket } from 'contexts/SocketContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import { TableType } from 'reducers/lobby';
import useGameService from 'services/GameService';
import {
  decompressData,
  humanizeCountdown,
  secondsToTime,
  toLocaleStringNumber
} from 'utils/functions';

import { TTournament } from 'components/TournamentTable/types';

import './TournamentDetails.scss';

type TournamentStructure = {
  tournamentId: number;
  bb: number;
  isBreak: number;
  level: string;
  lvlDisplay: number;
  lvlTitle: string;
  mins: number;
  num: string;
  sb: number;
  ante: number;
  lateRegistrationCountDown: number;
  currentLevel: string;
  nextLevel: number;
};

type PropType = {
  gameTitle: string;
  tournamentId: number;
  tableType: string;
  tournamentStatus: string;
  tournamentPrizePool: number;
};

interface PlayerTournament {
  playerId: string;
  status: string;
  position: number;
  prize: number;
  splitPosition: string;
  splitPrize: number;
  finalHandPot: number;
  schedule: string;
  gameID: number;
  game: string;
  endtime: number;
  myRebuys: number;
  isMultiFlightBreak: number;
  startsIn: number;
  breakTime: number;
  tStatus: string;
  time: number;
  gameNo: number;
  noOfPlayer: number;
  isOnBreak: number;
  currentLevel: number;
  rebuyExpireMins: number;
  addOnExpireMins: number;
}

function TournamentDetails({
  tournamentId,
  gameTitle,
  tableType,
  tournamentStatus,
  tournamentPrizePool
}: PropType) {
  const { setShowTournamentDetails, showTournamentDetails } = useContext(GameContext);
  const [rankPlayers, setRankPlayers] = useState([]);
  const [activeTables, setActiveTables] = useState([]);
  const [prizes, setPrizes] = useState([]);
  const [structuresData, setStructuresData] = useState<TournamentStructure[]>([]);
  const [entries, setEntries] = useState(0);

  const [average, setAverage] = useState(0);
  const [largestStack, setLargestStack] = useState(0);
  const [smallestStack, setSmallestStack] = useState(0);
  const [totalStack, setTotalStack] = useState(0);
  const { scale } = useContext(ScaleContext);

  const [selectedTab, setSelectedTab] = useState(1);
  const currentPlayer = useAppSelector((state) => state.user);
  const { openRebuyChips } = useContext(GameContext);
  const guid = useAppSelector((state) => state.user.playerGUID);
  const [lateRegistrationCountDown, setLateRegistrationCountDown] = useState(0);

  const [isMultiFlightBreak, setIsMultiFlightBreak] = useState<boolean>(false);
  const [isFinalMultiFlight, setIsFinalMultiFlight] = useState<boolean>(false);
  const [isMultiFlight, setIsMultiFlight] = useState<boolean>(false);
  const [isOnBreak, setIsOnBreak] = useState<boolean>(false);
  const [status, setStatus] = useState(null);
  const [nextLevelIsBreak, setNextLevelIsBreak] = useState<boolean>(false);
  const [isBreak, setIsBreak] = useState<boolean>(false);
  const [sb, setSb] = useState<number>(0);
  const [bb, setBb] = useState<number>(0);
  const [ante, setAnte] = useState<number>(0);
  const [nextSb, setNextSb] = useState<number>(0);
  const [nextBb, setNextBb] = useState<number>(0);
  const [nextAnte, setNextAnte] = useState<number>(0);
  const [nextLevel, setNextLevel] = useState<number>(-1);
  const [addOn, setAddOn] = useState<boolean>(false);
  const [activePlayers, setActivePlayers] = useState<number>(0);
  const [registeredPlayers, setRegisteredPlayers] = useState<number>(0);
  const [totalAddOns, setTotalAddOns] = useState(0);
  const [prizePool, setPrizePool] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [levelCountDown, setLevelCountDown] = useState<string>('-');
  const [isPostBalancing, setIsPostBalancing] = useState(0);
  const [playerTournamentDetails, setPlayerTournamentDetails] = useState<PlayerTournament | null>(
    null
  );
  const [currentLevel, setCurrentLevel] = useState(0);
  const game = useAppSelector((state) => state.currentTable.game);
  const { selectedTournament, tableTypeSelected, tournament } = useAppSelector(
    (state) => state.lobby
  );
  const [liveData, setLiveData] = useState<TTournament | null>(null);
  const { structures, tournamentPrizes, unsubscribeTournamentDetails, subscribeTournamentDetails } =
    useGameService();
  const tournamentDetailStatus = useAppSelector((state) => state.currentTable.tournamentStatus);

  const location = useLocation();
  const path = location.pathname;
  const handleTournamentDetails = () => {
    const [, value] = path.split('/');
    setShowTournamentDetails(false);
    if (value !== 'game') {
      unsubscribeTournamentDetails(tournamentId);
    }
  };

  const calculateTournamentPanelHeight = (scaleValue: number) => {
    if (scaleValue <= 0.5) {
      // X-Small scale
      return 'calc(100vh + 200px)';
    } else if (scaleValue > 0.5 && scaleValue <= 0.6) {
      // Small scale
      return 'calc(100vh + 180px)';
    } else if (scaleValue > 0.6 && scaleValue <= 0.75) {
      // Medium scale
      return 'calc(100vh + 90px)';
    } else if (scaleValue > 0.75 && scaleValue <= 0.9) {
      // Large scale
      return 'calc(100vh + 70px)';
    } else if (scaleValue > 0.9 && scaleValue < 1.0) {
      // Extra Large scale
      return '100vh';
    } else if (scaleValue >= 1.0) {
      // Normal scale
      return '90vh';
    }
  };

  useEffect(() => {
    let currentData;

    switch (tableTypeSelected) {
      case TableType.Standard:
      case TableType.FreezeOut:
        currentData = tournament.tournamentList.find(
          (data: TTournament) => data.gameID === selectedTournament?.gameID
        );
        break;
      case TableType.SitNGo:
        currentData = tournament.sitAndGoList.find(
          (data: TTournament) => data.gameID === selectedTournament?.gameID
        );
        break;
      default:
        currentData = null;
    }

    if (currentData) {
      setLiveData(currentData);
      if (showTournamentDetails) {
        subscribeTournamentDetails(currentData.tournamentId);
      }
    }
  }, [tableTypeSelected, game, selectedTournament]);

  useEffect(() => {
    const currentStructures = structuresData.filter(
      (f: TournamentStructure) => f.tournamentId === tournamentId
    );

    const level = game.currentLevel === 0 ? liveData?.currentLevel : game.currentLevel;

    if (currentStructures.length > 0) {
      setStructuresData(currentStructures);

      const structureInCurrentLevel = currentStructures.find(
        (f: TournamentStructure) => level === Number(f.level)
      );

      setSb(structureInCurrentLevel?.sb ?? 0);
      setBb(structureInCurrentLevel?.bb ?? 0);
      setAnte(structureInCurrentLevel?.ante ?? 0);

      const structureInNextLevel = currentStructures.find(
        (f: TournamentStructure) => Number(level) + 1 === +f.level
      );

      setNextLevelIsBreak(!!structureInNextLevel?.isBreak);
      setNextLevel(
        Number(structureInNextLevel?.level) < 0 || structureInNextLevel === undefined
          ? -1
          : Number(level) + 1
      );
      setNextSb(structureInNextLevel?.sb ?? 0);
      setNextBb(structureInNextLevel?.bb ?? 0);
      setNextAnte(structureInNextLevel?.ante ?? 0);
    }
  }, [game.currentLevel, structuresData]);

  useEffect(() => {
    setStructuresData([]);
    setRankPlayers([]);
    setActiveTables([]);
    setPrizes([]);
    structures(tournamentId);
    tournamentPrizes(tournamentId);

    return () => {
      unsubscribeTournamentDetails(tournamentId);
    };
  }, [tournamentId]);

  useEffect(() => {
    socket.on(GOT_TOURNAMENT_RANKING, (data: any) => {
      data = decompressData(data);
      const ranking = data.response.filter((f: any) => f.tournamentId === tournamentId);
      if (ranking.length > 0) {
        setRankPlayers(ranking);
      }
    });

    socket.on(GOT_TOURNAMENT_PRIZES, (data: any) => {
      data = decompressData(data);
      const prizes = data.response.filter((f: any) => f.tournamentId === tournamentId);
      if (prizes.length > 0) {
        setPrizes(prizes);
      }
    });

    socket.on(GOT_TOURNAMENT_STRUCTURE, (data: any) => {
      data = decompressData(data);
      let currentStructures: TournamentStructure[] = [];
      if (data.response.length > 0) {
        if (isMultiFlightBreak && isFinalMultiFlight) {
          currentStructures = Array.isArray(data.response[1]) ? data?.response[1] : [];
        } else {
          currentStructures = data?.response;
        }

        currentStructures = currentStructures.filter(
          (f: TournamentStructure) => f.tournamentId === tournamentId
        );

        const level = game.currentLevel === 0 ? liveData?.currentLevel : game.currentLevel;

        if (currentStructures.length > 0) {
          setStructuresData(currentStructures);

          const structureInCurrentLevel = currentStructures.find(
            (f: TournamentStructure) => level === Number(f.level)
          );

          setSb(structureInCurrentLevel?.sb ?? 0);
          setBb(structureInCurrentLevel?.bb ?? 0);
          setAnte(structureInCurrentLevel?.ante ?? 0);

          const structureInNextLevel = currentStructures.find(
            (f: TournamentStructure) => Number(level) + 1 === +f.level
          );

          setNextLevelIsBreak(!!structureInNextLevel?.isBreak);
          setNextLevel(
            Number(structureInNextLevel?.level) < 0 || structureInNextLevel === undefined
              ? -1
              : Number(level) + 1
          );
          setNextSb(structureInNextLevel?.sb ?? 0);
          setNextBb(structureInNextLevel?.bb ?? 0);
          setNextAnte(structureInNextLevel?.ante ?? 0);
        }
      }
    });

    socket.on(GOT_TOURNAMENT_ACTIVE_TABLES, (data: any) => {
      data = decompressData(data);
      const activeTablesData = data.response.filter((f: any) => f.tournamentId === tournamentId);
      if (activeTablesData.length > 0) {
        setActiveTables(activeTablesData);
      }
    });

    return () => {
      socket.off(GOT_TOURNAMENT_RANKING);
      socket.off(GOT_TOURNAMENT_STRUCTURE);
      socket.off(GOT_TOURNAMENT_PRIZES);
      socket.off(GOT_TOURNAMENT_ACTIVE_TABLES);
    };
  }, [guid, openRebuyChips, tournamentId, game, liveData]);

  useEffect(() => {
    const data = tournamentDetailStatus;
    if (data.length > 0) {
      const playerDetails = data.filter(
        (d: any) =>
          d.playerId === (guid === '' ? localStorage.getItem('playerGUID') : guid) &&
          d.tournamentId === tournamentId
      )[0] as PlayerTournament;

      setPlayerTournamentDetails(playerDetails);
      const filteredGame = data.filter((d: any) => d.tournamentId === tournamentId);
      setEntries(filteredGame.reduce((sum: any, item: any) => sum + (item.myRebuys || 0), 0));

      if (filteredGame.length > 0) {
        const firstTournamentStatus = filteredGame[0] as any;
        const tStatus = firstTournamentStatus?.tStatus ?? tournamentStatus;

        setStatus(tStatus);
        setAddOn(!!firstTournamentStatus?.addOn);
        setPrizePool(firstTournamentStatus?.prizepool ?? tournamentPrizePool ?? 0);
        setRegisteredPlayers(firstTournamentStatus?.registeredPlayers ?? 0);
        setIsPostBalancing(firstTournamentStatus?.isPostBalancing);

        if (tStatus !== 'R') {
          setIsMultiFlightBreak(!!firstTournamentStatus?.isMultiFlightBreak);
          setIsFinalMultiFlight(!!firstTournamentStatus?.isFinalMultiFlight);
          setIsMultiFlight(!!firstTournamentStatus?.isMultiFlight);
          setIsOnBreak(!!firstTournamentStatus?.isOnBreak);
          setIsBreak(!!firstTournamentStatus?.isBreak);
          setActivePlayers(firstTournamentStatus?.activePlayers ?? 0);
          setRegisteredPlayers(firstTournamentStatus?.registeredPlayers ?? 0);
          setTotalAddOns(firstTournamentStatus?.totalAddOns ?? 0);
          setLateRegistrationCountDown(firstTournamentStatus?.lateRegistrationCountDown ?? 0);
          setAverage(
            Math.trunc(new Decimal(firstTournamentStatus?.averageChips ?? '0').toDP(2).toNumber())
          );
          setLargestStack(
            Math.trunc(new Decimal(firstTournamentStatus?.largestChips ?? '0').toDP(2).toNumber())
          );
          setSmallestStack(
            Math.trunc(new Decimal(firstTournamentStatus?.smallestChips ?? '0').toDP(2).toNumber())
          );
          setTotalStack(new Decimal(firstTournamentStatus?.totalChips ?? '0').toDP(2).toNumber());
          setElapsedTime(firstTournamentStatus?.elapsedTime);
          setLevelCountDown(firstTournamentStatus?.levelCountDown);
          setCurrentLevel(firstTournamentStatus?.currentLevel ?? 0);
        }
      }
    }
  }, [tournamentDetailStatus]);

  const handleSelectTab = (param: number) => () => {
    setSelectedTab(param);
  };

  return (
    <div
      className={`tournamant-panel ${showTournamentDetails ? 'open' : ''}`}
      style={{
        transformOrigin: 'right center',
        transform: `scale(${scale})`,
        height: calculateTournamentPanelHeight(scale)
      }}>
      <div className='tournament-details'>
        <div>
          <FaAngleDoubleRight
            style={{ fontSize: '1.3rem', cursor: 'pointer' }}
            onClick={handleTournamentDetails}
          />
        </div>

        <div className='tournament-name' style={{ transform: `scale(${scale})` }}>
          <h2>
            <GiTrophy style={{ fontSize: '1.3rem' }} />
            {gameTitle}
            <GiTrophy style={{ fontSize: '1.3rem' }} />
          </h2>
        </div>

        <div className='row'>
          <div className='box'>
            <p className='label-name'>Blind Level</p>
            <p>
              {isMultiFlightBreak || status !== 'P'
                ? '-'
                : isBreak
                ? 'Break'
                : toLocaleStringNumber(sb) +
                  '/' +
                  toLocaleStringNumber(bb) +
                  ' (' +
                  toLocaleStringNumber(ante) +
                  ')'}
            </p>
          </div>
          <div className='box'>
            <p className='label-name'>Next Blind Level</p>
            <p>
              {isMultiFlightBreak || status !== 'P'
                ? '-'
                : isOnBreak && isMultiFlight && isFinalMultiFlight
                ? toLocaleStringNumber(sb) +
                  '/' +
                  toLocaleStringNumber(bb) +
                  ' (' +
                  toLocaleStringNumber(ante) +
                  ')'
                : nextLevelIsBreak
                ? 'Break'
                : Number(nextLevel) !== -1
                ? toLocaleStringNumber(nextSb) +
                  '/' +
                  toLocaleStringNumber(nextBb) +
                  ' (' +
                  toLocaleStringNumber(nextAnte) +
                  ')'
                : 'None'}
            </p>
          </div>
          <div className='box'>
            <p className='label-name'>
              Player / Entry {tableType === 'm' ? (addOn ? ' / AddOn' : '') : ''}
            </p>
            <p>
              {isMultiFlightBreak || status !== 'P' ? ' - ' : activePlayers}
              {isMultiFlightBreak || status !== 'P' ? ' - ' : ' / ' + (registeredPlayers + entries)}
              {tableType === 'm'
                ? isMultiFlightBreak || status !== 'P'
                  ? ' - '
                  : addOn
                  ? ' / ' + totalAddOns
                  : ''
                : ''}
            </p>
          </div>
        </div>

        <div className='row'>
          <div className='box'>
            <p className='label-name'>Prize Pool</p>${isMultiFlightBreak ? '-' : prizePool}
          </div>
          <div className='box'>
            <p className='label-name'>Level Countdown</p>
            <p>{status !== 'P' || isPostBalancing ? '-' : levelCountDown}</p>
          </div>
          <div className='box'>
            <p className='label-name'>Elapsed Time</p>
            <p>
              {isMultiFlightBreak || status !== 'P'
                ? '-'
                : status === 'P'
                ? secondsToTime(elapsedTime)
                : status === 'R'
                ? 'Registering'
                : status === 'A'
                ? 'Announcing'
                : 'Finished'}
            </p>
          </div>
        </div>

        {tableType === 'm' && (
          <div className='row'>
            <div
              className={'long-box'} // gameType === 'Bounty' ? 'medium-box' :
              style={{ justifyContent: 'normal' }}>
              <div className='column'>
                <div className='label-name'>
                  <p>Late Registration</p>
                </div>
              </div>
              <div
                className='column'
                style={{
                  width: '75%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '30px'
                }}>
                <p>
                  {status === 'R' || isPostBalancing
                    ? 'Registering'
                    : status === 'A'
                    ? 'Announcing'
                    : humanizeCountdown(lateRegistrationCountDown)}
                </p>
              </div>
            </div>
            {/* gameType === 'Bounty' && (
              <div className='box'>
                <p className='label-name'>Total Bounties</p>$
                {bountyAmount *
                  (activePlayers === 0 && status !== 'P' ? registeredPlayers : activePlayers)}
              </div>
            )*/}
          </div>
        )}

        <div className='row'>
          <div className='long-box'>
            <div className='column-center'>
              <div className='label-name'>
                <p>Stacks</p>
              </div>
            </div>
            <div className='column'>
              <p>Average</p>
              <p>{status === 'P' ? toLocaleStringNumber(average) : 0}</p>
            </div>
            <div className='column'>
              <p>Largest</p>
              <p>{status === 'P' ? toLocaleStringNumber(largestStack) : 0}</p>
            </div>
            <div className='column'>
              <p>Smallest</p>
              <p>{status === 'P' ? toLocaleStringNumber(smallestStack) : 0}</p>
            </div>
            <div className='column'>
              <p>Total</p>
              <p>{status === 'P' ? toLocaleStringNumber(totalStack) : 0}</p>
            </div>
          </div>
        </div>
        <Divider />
        {status !== 'P' ? (
          <div className='row-table' style={{ width: '990px' }}>
            <div
              className={`column-button ${selectedTab === 1 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(1)}>
              Registered Players
            </div>
            <div
              className={`column-button ${selectedTab === 4 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(4)}>
              BLINDS
            </div>
          </div>
        ) : (
          <div className='row-table'>
            <div
              className={`column-button ${selectedTab === 1 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(1)}>
              RANKING
            </div>
            <div
              className={`column-button ${selectedTab === 2 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(2)}>
              PRIZES
            </div>
            <div
              className={`column-button ${selectedTab === 3 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(3)}>
              TABLES
            </div>
            <div
              className={`column-button ${selectedTab === 4 ? 'active-tab' : 'inactive-tab'}`}
              onClick={handleSelectTab(4)}>
              BLINDS
            </div>
          </div>
        )}
      </div>

      {/* RANKING */}
      {selectedTab === 1 && (
        <div className='div-table'>
          <table className='tournament-table'>
            <thead className='thead-table'>
              <tr>
                <th className='th' style={{ width: '20%' }}>
                  Ranking
                </th>
                <th className='th' style={{ width: '45%' }}>
                  Name
                </th>
                <th className='th' style={{ width: '20%' }}>
                  Chips
                </th>
                {tableType === 'm' && (
                  <th className='th' style={{ width: '15%' }}>
                    R+A
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rankPlayers.length !== 0 ? (
                rankPlayers?.map((player: any, index: any) => {
                  return (
                    player.playerId === currentPlayer.playerGUID &&
                    index > 9 && (
                      <tr
                        key={`rank-player-${index}`}
                        className={'current-place'}
                        style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        <td className='td'>{index + 1}</td>
                        <td className='td'>
                          {index === 0 ? (
                            <RiVipCrownFill className='ai-outline-user first-place' />
                          ) : index === 1 ? (
                            <RiVipCrownFill className='ai-outline-user seconds-place' />
                          ) : index === 2 ? (
                            <RiVipCrownFill className='ai-outline-user third-place' />
                          ) : (
                            ''
                          )}{' '}
                          {player.displayName}
                        </td>
                        <td className='td'>{toLocaleStringNumber(player.finalHandPot)}</td>
                        <td className='td'>{player.ra}</td>
                      </tr>
                    )
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'R'
                        ? 'Registering'
                        : status === 'A'
                        ? 'Announcing'
                        : 'Loading...'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'P' && (
                        <CircularProgress style={{ color: 'white', padding: '10px' }} />
                      )}
                    </td>
                  </tr>
                </>
              )}
              {rankPlayers?.map((player: any, index: any) => {
                return (
                  <tr
                    key={`rank-player-${index}`}
                    className={
                      player.playerId === currentPlayer.playerGUID && index < 10
                        ? 'current-place'
                        : ''
                    }
                    style={{
                      fontWeight:
                        player.playerId === currentPlayer.playerGUID && index < 10
                          ? 'bold'
                          : 'normal',
                      fontSize:
                        player.playerId === currentPlayer.playerGUID && index < 10 ? '20px' : '18px'
                    }}>
                    <td className='td'>{index + 1}</td>
                    <td className='td'>
                      {index === 0 ? (
                        <RiVipCrownFill className='ai-outline-user first-place' />
                      ) : index === 1 ? (
                        <RiVipCrownFill className='ai-outline-user seconds-place' />
                      ) : index === 2 ? (
                        <RiVipCrownFill className='ai-outline-user third-place' />
                      ) : (
                        ''
                      )}{' '}
                      {player.displayName}
                    </td>
                    <td className='td'>{toLocaleStringNumber(player.finalHandPot)}</td>
                    {tableType === 'm' && <td className='td'>{player.ra}</td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* PRIZES */}
      {selectedTab === 2 && (
        <div className='div-table'>
          <table className='tournament-table '>
            <thead className='thead-table'>
              <tr>
                <th className='prizes-th label-center w-20 table-border-bottom'>Ranking</th>
                <th className='prizes-th label-right w-80 table-border-bottom'>Rewards</th>
              </tr>
            </thead>
            <tbody>
              {prizes.length !== 0 ? (
                prizes?.map((prize: any, index: any) => {
                  return (
                    <tr key={`prize-${index}`}>
                      <td className='td label-center table-border-bottom'>{prize.rank}</td>
                      <td className='td label-right table-border-bottom'>
                        {prize.isTicket ? (
                          <LocalActivityIcon style={{ color: '#FFCF6C' }} />
                        ) : (
                          `$${prize.percentPrizePool}`
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'R'
                        ? 'Registering'
                        : status === 'A'
                        ? 'Announcing'
                        : 'Loading...'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'P' && (
                        <CircularProgress style={{ color: 'white', padding: '10px' }} />
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* TABLES */}
      {selectedTab === 3 && (
        <div className='div-table'>
          <table className='tournament-table'>
            <thead className='thead-table'>
              <tr>
                <th className='th' style={{ textAlign: 'left', paddingLeft: '30px' }}>
                  Table #
                </th>
                <th className='th'>No. of Players</th>
                <th className='th'>Low / Hi Stack</th>
                {playerTournamentDetails?.status !== 'P' && <th className='th'>Observe</th>}
              </tr>
            </thead>
            <tbody>
              {activeTables?.map((table: any, index: any) => {
                return (
                  <tr key={`active-tables-${index}`}>
                    <td className='td'>
                      {playerTournamentDetails?.status !== 'P' ? (
                        <a
                          href={'/game/' + table?.gameID + '/type/' + table?.game + '?observe=yes'}
                          target='_blank'>
                          <div className='table-layout'>{index + 1}</div>
                        </a>
                      ) : (
                        <div className='table-layout'>{index + 1}</div>
                      )}
                    </td>
                    <td className='td'>
                      <AiOutlineUser className='ai-outline-user' />
                      {table.noOfPlayers}
                    </td>
                    <td className='td'>
                      {toLocaleStringNumber(table.lowestStack)}
                      {' / '}
                      {toLocaleStringNumber(table.largestStack)}
                    </td>
                    {playerTournamentDetails?.status !== 'P' && (
                      <td className='td'>
                        {game?.gameID !== table?.gameID ? (
                          <a
                            href={
                              '/game/' + table?.gameID + '/type/' + table?.game + '?observe=yes'
                            }
                            target='_blank'>
                            <OpenInNewIcon style={{ color: 'white' }} />
                          </a>
                        ) : (
                          'Observed'
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* BLINDS */}
      {selectedTab === 4 && (
        <div className='div-table'>
          <table className='tournament-table'>
            <thead className='thead-table'>
              <tr>
                <th className='th'>Level</th>
                <th className='th'>Time</th>
                <th className='th'>Blinds</th>
                <th className='th'>Ante</th>
              </tr>
            </thead>
            <tbody>
              {structuresData.length !== 0 ? (
                structuresData?.map((structure: any, index: any) => {
                  return (
                    <tr
                      key={`structures-data-${index}`}
                      style={{
                        color:
                          currentLevel === structure.level && status !== 'R' ? '#e98c26' : 'white',
                        fontWeight:
                          currentLevel === structure.level && status !== 'R' ? 'bold' : 'normal'
                      }}>
                      <td className='td'>{structure.isBreak ? 'Break' : structure.lvlDisplay}</td>
                      <td className='td'>{structure.mins} min(s)</td>
                      <td className='td'>
                        {structure.isBreak ? '-' : structure.sb + '/' + structure.bb}
                      </td>
                      <td className='td'>{structure.isBreak ? '-' : structure.ante}</td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'R'
                        ? 'Registering'
                        : status === 'A'
                        ? 'Announcing'
                        : 'Loading...'}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} className='td'>
                      {status === 'P' && (
                        <CircularProgress style={{ color: 'white', padding: '10px' }} />
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default TournamentDetails;
