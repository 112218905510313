import React, { useContext, useEffect, useRef, useState } from 'react';
import { faLaugh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, createTheme, ThemeProvider, ToggleButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { LEFT_PANE_WIDTH } from 'constants/gameConstants';
import ScaleContext from 'contexts/ScaleContext';
import TexasChatContext from 'contexts/TexasChatContext';
import EmojiPicker from 'emoji-picker-react';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { ZIndex } from 'styles/zIndex';
import { toTitleCase } from 'utils/functions';

import styles from './Chat.module.scss';

const NO_AUTO_SCROLL_AREA_PERCENTAGE = 0.95;

const theme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        },
        grouped: {
          border: 0,
          '&:not(:first-of-type)': {
            borderRadius: '0.25rem'
          },
          '&:first-of-type': {
            borderRadius: '0.25rem'
          }
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: 'white',
          fontWeight: 'normal',
          backgroundColor: '#E98C26',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#A7651C'
          },
          marginRight: '0.5rem',
          ':last-child': {
            marginRight: '0rem'
          },
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#E98C26',
            '&:hover': {
              backgroundColor: '#A7651C'
            }
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }
      }
    }
  }
});

const UNMUTE_MESSAGE_HIDE_DELAY = 15000; //15 seconds

const Chat = () => {
  const { isOpen } = useContext(TexasChatContext);
  const { textChat, clearUnreadMessage } = useGameService();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatMessagesRef = useRef<HTMLInputElement>(null);
  const game = useAppSelector((state) => state.currentTable.game);
  const players = useAppSelector((state) => state.currentTable.players);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const playerIsMuted = +players[+seatId]?.isMuted;
  const playerMuteDuration = players[+seatId]?.duration;
  const { chatMessages, lastChatMessage, gameID, game: gameTable } = game;
  const currentPlayerGUID = useAppSelector((state) => state.user.playerGUID);
  const [isShowEmojiPicker, setIsShowEmojiPicker] = useState(false);
  const currentPlayer = useAppSelector((state) => state.currentTable.currentPlayer);
  const { isSeated, seatStartTime } = currentPlayer;
  const prevChatMessagesLengthRef = useRef(chatMessages.length);
  const { scale } = useContext(ScaleContext);
  const chatMessagesContainerRef = useRef<HTMLDivElement>(null);

  const mutedPlayers = players?.filter((f) => +f?.isMuted && f.GUID !== currentPlayerGUID) ?? [];

  const playerIsMutedPrev = useRef<number>();

  const [isUnmuted, setIsUnmuted] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!playerIsMuted && playerIsMutedPrev.current) {
      playerIsMutedPrev.current = playerIsMuted;

      setIsUnmuted(true);

      timeoutId = setTimeout(() => {
        setIsUnmuted(false);
      }, UNMUTE_MESSAGE_HIDE_DELAY);
    }

    playerIsMutedPrev.current = playerIsMuted;

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [playerIsMuted]);

  const sendMessage = () => {
    if ((chatMessagesRef.current?.value ?? '').length > 0) {
      textChat(chatMessagesRef.current?.value ?? '');
    }
    (chatMessagesRef.current as any).value = '';

    messagesEndRef.current?.scrollIntoView({
      behavior: prevChatMessagesLengthRef.current !== chatMessages.length ? 'smooth' : 'auto',
      block: 'center'
    });
  };

  const handleKeyDown = (event: any) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const addEmoji = (e: any) => {
    const sym = e.unified.split('-');
    const codesArray: any = [];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    const emoji = String.fromCodePoint(...codesArray);
    if (chatMessagesRef.current) {
      (chatMessagesRef.current as any).value += emoji;
    }
    setIsShowEmojiPicker(false);
  };

  useEffect(() => {
    if (messagesEndRef?.current && chatMessagesContainerRef?.current && isOpen) {
      clearUnreadMessage(gameTable, gameID, currentPlayerGUID);

      const { scrollHeight, clientHeight, scrollTop } = chatMessagesContainerRef.current;
      const autoScrollRange = (scrollHeight - clientHeight) * NO_AUTO_SCROLL_AREA_PERCENTAGE;

      if (scrollTop > autoScrollRange) {
        setTimeout(() => {
          messagesEndRef.current?.scrollIntoView({
            behavior: prevChatMessagesLengthRef.current !== chatMessages.length ? 'smooth' : 'auto',
            block: 'center'
          });
        }, 250);
      }
    }

    prevChatMessagesLengthRef.current = chatMessages.length;
  }, [lastChatMessage?.messageGUID, isOpen, gameID, currentPlayerGUID]);

  return (
    <div
      className={styles.chat}
      style={{
        height: '100%',
        zIndex: ZIndex.overlay,
        width: LEFT_PANE_WIDTH * scale,
        visibility: isOpen ? 'visible' : 'hidden'
      }}>
      <div className={styles.header}>
        <div
          style={{
            padding: `calc(1rem * ${scale})`,
            fontSize: `max(calc(1rem * ${scale}), 12px)`
          }}>
          Game Chat
        </div>
      </div>
      <div ref={chatMessagesContainerRef} className={styles.body} style={{ height: '100%' }}>
        <div
          className={styles.chat_list}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: `calc(1rem * ${scale})`,
            padding: `calc(1rem * ${scale})`,
            minHeight: '100%',
            justifyContent: 'end'
          }}>
          {chatMessages
            .filter((chatMessage) => seatStartTime && chatMessage.ts >= seatStartTime)
            .map((chatMessage, i) => (
              <div
                className={styles.chat_item}
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: `calc(18px * ${scale})`,
                  alignItems: 'flex-end',
                  justifyContent: chatMessage.GUID === currentPlayerGUID ? 'end' : 'start'
                }}>
                {chatMessage.GUID !== currentPlayerGUID && (
                  <Avatar
                    className={styles.player_avatar}
                    sx={{ width: 28 * scale, height: 28 * scale }}
                    src={chatMessage.avatar}
                  />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  {chatMessage.GUID !== currentPlayerGUID && (
                    <div style={{ fontSize: '10px' }}>{toTitleCase(chatMessage.displayName)}</div>
                  )}
                  <div
                    className={styles.chat_detail}
                    style={{
                      wordBreak: 'break-all',
                      flexGrow: 0.01,
                      marginLeft: 0
                    }}>
                    <p
                      style={{
                        whiteSpace: 'pre-line',
                        fontSize: `max(calc(.9375rem * ${scale}), 11px)`
                      }}>
                      {chatMessage.message}
                    </p>
                  </div>
                </div>
                {chatMessage.GUID === currentPlayerGUID && (
                  <Avatar
                    className={styles.player_avatar}
                    style={{ width: 28 * scale, height: 28 * scale }}
                    src={chatMessage.avatar}
                  />
                )}
              </div>
            ))}
        </div>
        <div ref={messagesEndRef} />
      </div>

      {isUnmuted && (
        <div
          style={{
            backgroundColor: '#1a1a1a',
            display: 'flex',
            flexDirection: 'column',
            gap: 11 * scale,
            padding: 11 * scale
          }}>
          <div
            style={{
              fontSize: `calc(1rem * ${scale})`,
              color: '#FFFFFF',
              paddingTop: 6 * scale,
              paddingRight: 11 * scale,
              paddingBottom: 11 * scale,
              paddingLeft: 6 * scale,
              backgroundColor: '#333',
              borderRadius: 6
            }}>
            Messaging has been reactivated. Please adhere to community guidelines to avoid further
            action.
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: '#1a1a1a',
          display: 'flex',
          flexDirection: 'column',
          gap: 11 * scale,
          padding: 11 * scale
        }}>
        {mutedPlayers.map((p: any) => (
          <div
            style={{
              fontSize: `calc(1rem * ${scale})`,
              color: '#FFFFFF',
              paddingTop: 6 * scale,
              paddingRight: 11 * scale,
              paddingBottom: 11 * scale,
              paddingLeft: 6 * scale,
              backgroundColor: '#333',
              borderRadius: 6
            }}>
            {p.displayName} has been suspended from chat
          </div>
        ))}
      </div>

      {isSeated && (
        <ThemeProvider theme={theme}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              gap: 11 * scale,
              padding: 11 * scale,
              width: LEFT_PANE_WIDTH * scale
            }}>
            {+playerIsMuted === 1 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 11 * scale,
                  textAlign: 'center'
                }}>
                <span style={{ fontSize: `calc(1rem * ${scale})`, color: '#FFFFFF' }}>
                  {playerMuteDuration}
                </span>
              </div>
            ) : (
              <TextField
                multiline
                maxRows={2}
                disabled={+playerIsMuted === 1}
                fullWidth
                inputRef={chatMessagesRef}
                onKeyDown={handleKeyDown}
                placeholder='Message'
                InputProps={{
                  sx: {
                    fontSize: `max(calc(1rem * ${scale}), 11px)`,
                    padding: `${16.5 * scale}px ${14 * scale}px`
                  }
                }}
              />
            )}
            <div style={{ position: 'relative' }}>
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {playerIsMuted === 0 && (
                  <>
                    <Button
                      variant='text'
                      style={{
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                        width: 'auto',
                        fontSize: `calc(1.2rem * ${scale})`
                      }}
                      onClick={() => setIsShowEmojiPicker((prev) => !prev)}
                      title={'Add Emoji'}>
                      <FontAwesomeIcon
                        icon={faLaugh}
                        style={{ color: isShowEmojiPicker ? '#E98C26' : '#FFFFFF' }}
                      />
                    </Button>

                    <ToggleButton
                      value='send'
                      onClick={sendMessage}
                      title='Send'
                      style={{
                        padding: `${7 * scale}px ${11 * scale}px`,
                        fontSize: `max(calc(0.875rem * ${scale}), 11px)`,
                        lineHeight: 1.75 * scale
                      }}>
                      Send
                    </ToggleButton>
                  </>
                )}
              </div>
              {isShowEmojiPicker && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 31 * scale,
                    transform: `scale(${scale})`,
                    transformOrigin: `bottom left`
                  }}>
                  <EmojiPicker onEmojiClick={addEmoji} />
                </div>
              )}
            </div>
          </div>
        </ThemeProvider>
      )}
    </div>
  );
};

export default Chat;
