import React, { useContext, useEffect, useRef, useState } from 'react';
import { a, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import { DEALER_HEIGHT, DEALER_WIDTH } from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';

export const DEALER_DELAY = 1000;

export const DealerButton = () => {
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const dealer = useAppSelector<any>((state) => state.currentTable.game.dealer);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const hand = useAppSelector((state) => state.currentTable.game.hand);

  const { betPositions } = useContext(GameContext);

  const prevFinalDealerPositionLength = useRef(0);

  const [prevDealerPosition, setPrevDealerPosition] = useState<number>(0);
  const [nextDealerPosition, setNextDealerPosition] = useState<number>(0);
  const [resetAnimation, setResetAnimation] = useState<boolean>(false);
  const betLastIndex = betPositions.length - 1;

  useEffect(() => {
    const appSeats = players?.slice(1);
    const seatCount = appSeats.length;
    const dealerSeatNumber = +dealer;
    let finalDealerPositionLength = 0;

    if (dealerSeatNumber > 0) {
      let positionIndex = seatId < 1 ? dealerSeatNumber - 1 : dealerSeatNumber - seatId;

      if (positionIndex < 0) {
        positionIndex = seatCount + positionIndex;
      }

      const betPositionLength = ((betPositions.length / seatCount) * positionIndex) | 0;
      const position = betPositions[betPositionLength];

      if (position) {
        finalDealerPositionLength = betPositionLength;

        if (
          (!position.isTop && position.isRight) ||
          (position.isBottom && position.isCenter) ||
          (position.isTop && position.isLeft)
        ) {
          finalDealerPositionLength = betPositionLength - 41;
        }

        if (
          (!position.isTop && position.isLeft) ||
          (position.isTop && position.isCenter) ||
          (position.isTop && position.isRight)
        ) {
          finalDealerPositionLength = betPositionLength + 41;
        }
      }

      if (hand !== '' && +hand > 0) {
        if (prevFinalDealerPositionLength.current !== finalDealerPositionLength) {
          setNextDealerPosition((prev) => {
            const newPrevDealerPosition = prev % betLastIndex;
            setPrevDealerPosition(newPrevDealerPosition);
            return finalDealerPositionLength > newPrevDealerPosition
              ? finalDealerPositionLength
              : finalDealerPositionLength + betLastIndex;
          });
          setResetAnimation(true);
        }
        prevFinalDealerPositionLength.current = finalDealerPositionLength;
      }
    }
  }, [betLastIndex, betPositions, dealer, hand, players, seatId]);

  const { dealerPosition } = useSpring({
    from: { dealerPosition: prevDealerPosition },
    to: { dealerPosition: nextDealerPosition },
    delay: DEALER_DELAY,
    reset: resetAnimation,
    onResolve: () => {
      setResetAnimation(false);
    }
  });

  return (
    <>
      {nextDealerPosition !== 0 && +dealer > 0 && hand !== '' && +hand > 0 && (
        <a.div
          style={{
            position: 'absolute',
            left: dealerPosition.to((t) => betPositions[(t | 0) % betLastIndex]?.left),
            top: dealerPosition.to((t) => betPositions[(t | 0) % betLastIndex]?.top)
          }}>
          <Paper sx={DealerButtonStyle}>D</Paper>
        </a.div>
      )}
    </>
  );
};

const DealerButtonStyle = {
  backgroundColor: '#8c3041',
  width: DEALER_WIDTH,
  height: DEALER_HEIGHT,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: '#EDEDED',
  zIndex: 2
};
