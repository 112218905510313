import { useContext, useEffect, useState } from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GOT_TOURNAMENT_ADDON } from 'constants/eventConstants';
import GameContext from 'contexts/GameContext';
import { socket } from 'contexts/SocketContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { decompressData } from 'utils/functions';

const AddOnChipsModal = () => {
  const { addOn } = useGameService();
  const { openAddOnChips, setOpenAddOnChips } = useContext(GameContext);
  const addOnPrice = useAppSelector((state) => state.currentTable.game.addOnPrice);
  const addOnChips = useAppSelector((state) => state.currentTable.game.addOnChips);
  const entryFee = useAppSelector((state) => state.currentTable.game.entryFee);
  const [msgTitle, setMsgTitle] = useState('');
  const [msg, setMsg] = useState('');
  const [remainingAddOn, setRemainingAddOn] = useState(0);
  const tournamentStatus = useAppSelector((state) => state.currentTable.tournamentStatus);
  const game = useAppSelector((state) => state.currentTable.game);
  const playerGUID = useAppSelector((state) => state.user.playerGUID);

  useEffect(() => {
    if (tournamentStatus.length > 0) {
      const filteredGame = tournamentStatus.filter(
        (d: any) => d.tournamentId === game.tournamentId && d.playerId === playerGUID
      );
      if (filteredGame.length > 0) {
        const firstTournamentStatus = filteredGame[0];
        setRemainingAddOn(+firstTournamentStatus['addOn'] - +firstTournamentStatus['myAddons']);
      }
    }
  }, [tournamentStatus, playerGUID]);

  const handleAddChips = () => {
    addOn();
    setOpenAddOnChips(false);
    setMsgTitle('');
    setMsg('');
  };

  const cancelAddChips = () => {
    setMsgTitle('');
    setMsg('');
    setOpenAddOnChips(false);
  };

  useEffect(() => {
    socket.on(GOT_TOURNAMENT_ADDON, (data: any) => {
      data = decompressData(data);
      if (data) {
        setOpenAddOnChips(true);
        setMsgTitle(data?.title);
        setMsg(data?.msg);
      } else {
        setMsgTitle('');
        setMsg('');
        setOpenAddOnChips(false);
      }
      return () => {
        socket.off(GOT_TOURNAMENT_ADDON);
      };
    });
  });

  return (
    <Modal open={openAddOnChips}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msgTitle !== '' ? msgTitle : 'ADD ON CHIPS'}
        </Typography>
        <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msg !== ''
            ? msg
            : `Please note that proceeding with this action will incur a $${Decimal.sum(
                addOnPrice === null ? 0 : addOnPrice,
                entryFee === null ? 0 : entryFee
              )
                .toDP(2)
                .toNumber()} charge, and you
          will receive ${addOnChips} chips. You still have ${Math.max(
                0,
                remainingAddOn
              )} more add-on(s) left.`}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={cancelAddChips}>
              {msgTitle !== '' ? 'Close' : 'Cancel'}
            </Button>
          </ThemeProvider>
          {msgTitle === '' && (
            <ThemeProvider theme={AddChipsBtnStyle}>
              <Button sx={{ display: 'block' }} variant='contained' onClick={handleAddChips}>
                Proceed
              </Button>
            </ThemeProvider>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default AddOnChipsModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const AddChipsBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
