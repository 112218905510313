import React, {
  CSSProperties,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Toaster } from 'react-hot-toast';
import { useParams, useSearchParams } from 'react-router-dom';
import { audioPath } from 'constants/audioConstants';
import { CONNECT, REGISTER_SOCKET } from 'constants/eventConstants';
import { LEFT_PANE_WIDTH } from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import GlobalContext from 'contexts/GlobalContext';
import ScaleContext from 'contexts/ScaleContext';
import { SocketContext } from 'contexts/SocketContext';
import TableHistoryContext from 'contexts/TableHistoryContext';
import TexasChatContext from 'contexts/TexasChatContext';
import Decimal from 'decimal.js';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateHandRank } from 'reducers/user';
import useGameService from 'services/GameService';
import useSound from 'use-sound';
import { getTransformScale } from 'utils/functions';

import Chat from 'components/Chat/Chat';
import FreshDeskLink from 'components/FreshDeskLink';
import GameCommsMenu from 'components/GameCommsMenu/GameCommsMenu';
import LoadingState from 'components/Loading/LoadingState';
import SoftLoadingState from 'components/Loading/SoftLoadingState';
import PlayerTables from 'components/PlayerTables';
import Table from 'components/Table';
import TournamentDetails from 'components/Table/TournamentDetails';
import TableHistory from 'components/TableHistory/TableHistory';
import UserActions from 'components/UserActions';
import AddChipsModal from 'components/UserActions/AddChipsModal';
import AddFundsModal from 'components/UserActions/AddFundsModal';
import AddOnChipsModal from 'components/UserActions/AddOnChipsModal';
import AutoFoldMessageModal from 'components/UserActions/AutoFoldMessageModal';
import BuyInModal from 'components/UserActions/BuyInModal';
import DuplicateScreenModal from 'components/UserActions/DuplicateScreenModal';
import ErrorMsgModal from 'components/UserActions/ErrorMsgModal';
import RebuyChipsModal from 'components/UserActions/RebuyChipsModal';
import TournamentScreenModal from 'components/UserActions/TournamentScreenModal';
import WaitForBuyInModal from 'components/UserActions/WaitForBuyInModal';

import 'App.scss';

const Game = () => {
  const { gameID, gameType } = useParams();
  const currentTable = useAppSelector<any>((state) => state.currentTable);
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const hand = useAppSelector<any>((state) => state.currentTable.game.hand);
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);
  const lastplayer = useAppSelector<any>((state) => state.currentTable.game.lastplayer);
  const tournamentId = useAppSelector<any>((state) => state.currentTable.game.tournamentId);
  const title = useAppSelector<any>((state) => state.currentTable.game.title);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const prizePool = useAppSelector<any>((state) => state.currentTable.game.prizePool);
  const tStatus = useAppSelector<any>((state) => state.currentTable.game.status);
  const triggerBuyIn = useAppSelector<any>((state) => state.currentTable.game.triggerBuyIn);
  const serverTime = useAppSelector((state) => state.serverDetails.serverTime);
  const elementRef = useRef<HTMLDivElement>(null);
  const buyInTriggerTimer = useAppSelector<any>(
    (state) => state.currentTable.game.buyInTriggerTimer
  );

  const isSeated = useAppSelector((state) => state.currentTable.currentPlayer.isSeated);
  const userBalance = useAppSelector((state) => state.user.playerBankAmount);
  const finalBalance = Decimal.sum(
    new Decimal(userBalance),
    new Decimal(isSeated ? players[seatId]?.stack : 0)
  );

  const { joinTable, getTableCards } = useGameService();

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const socket = useContext(SocketContext);

  const { playSound, setCloseBuyInModal, isDuplicateAccess, setOpenAddFunds, setBuyinAmount } =
    useContext(GlobalContext);
  const [playTableCard] = useSound(audioPath.tableFlipCards);
  const [playChipsToPot] = useSound(audioPath.chipsToPot);
  const [playBetChips] = useSound(audioPath.betChips);
  const { setPlayerMultiRunVote } = useContext(GameContext);
  const { setScale } = useContext(ScaleContext);
  const [isDealingPlayerCard, setIsDealingPlayerCard] = useState(true);
  const currentScaleRef = useRef(1);
  const { isOpen: isOpenTableHistory } = useContext(TableHistoryContext);
  const { isOpen: isOpenTexasChat } = useContext(TexasChatContext);
  const showLeftPane = isOpenTableHistory || isOpenTexasChat;
  const [playCheck] = useSound(audioPath.check);
  const [playFold] = useSound(audioPath.fold);
  const { leaveTable } = useGameService();
  const { setOpenAskBuyin, setIsTriggerBuyIn, isTriggerBuyIn, openAskBuyin } =
    useContext(GlobalContext);
  const tablelow = useAppSelector<any>((state) => state.currentTable.game.tablelow);
  const [hasTriggered, setHasTriggered] = useState(false);

  const isValidObserveMode = (observeMode: any) => {
    if (observeMode === 'yes' || observeMode === 'no') {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isSeated && serverTime - triggerBuyIn > buyInTriggerTimer && triggerBuyIn > 0) {
      setIsTriggerBuyIn(true);
    }
  }, [serverTime]);

  useEffect(() => {
    if (
      isSeated &&
      !hasTriggered &&
      !openAskBuyin &&
      serverTime - triggerBuyIn > buyInTriggerTimer &&
      triggerBuyIn > 0
    ) {
      leaveTable(true);
      setBuyinAmount(tablelow);
      setOpenAskBuyin(true);
      setHasTriggered(true);
    }

    if (triggerBuyIn === -1) {
      setHasTriggered(false);
      setIsTriggerBuyIn(false);
    }
  }, [isTriggerBuyIn, triggerBuyIn, openAskBuyin]);

  useEffect(() => {
    if (isValidObserveMode(searchParams.get('observe'))) {
      joinTable(gameID, gameType);
    } else {
      window.location.href = window.location.origin;
    }
  }, [gameID, joinTable, searchParams]);

  useEffect(() => {
    socket.on(CONNECT, () => {
      socket.emit(REGISTER_SOCKET, { page: 'game', gameID: gameID, gameType: gameType });
      socket.emit(REGISTER_SOCKET, { page: 'lobby' });
    });

    if (tournamentId) {
      socket.emit(REGISTER_SOCKET, {
        page: 'tournament',
        tournamentId: tournamentId,
        isRegister: true
      });
    }

    return () => {
      socket.off(CONNECT);
    };
  }, [socket, gameID, tournamentId]);

  useEffect(() => {
    getTableCards();

    switch (hand) {
      case '':
        if (isSeated && finalBalance.lt(game.minBalanceToPlay) && game.tabletype === 's') {
          setOpenAddFunds(true);
        }
        dispatch(updateHandRank({ name: 'High Card', value: 0 }));
        setPlayerMultiRunVote('0');
        break;
      case '1':
        //Transfer this to hand == 15?
        dispatch(updateHandRank({ name: 'High Card', value: 0 }));
        setPlayerMultiRunVote('0');
        setCloseBuyInModal(false);
        break;
      case '4':
        // playSoundDealingCard('dealingCards');
        break;
      case '5':
        // Deal CARDS
        if (isDealingPlayerCard) {
          playTableCard();
          setIsDealingPlayerCard(false);
        }
        break;
      case '6':
        // Deal FLOP
        playTableCard();
        break;
      case '8':
        // Deal TURN
        playTableCard();
        break;
      case '10':
        // Deal RIVER
        playTableCard();
        break;
      case '13':
      case '14':
        playTableCard();
        break;
      case '15':
        // ShowCard
        playChipsToPot();
        setIsDealingPlayerCard(true);
        break;
    }

    if (+hand >= 4 && +hand <= 11) {
      const lastaction = game[`p${lastplayer}action`]?.toLowerCase();
      switch (lastaction) {
        case 'ante':
          playBetChips();
          break;
        case 'check':
          playCheck();
          break;
        case 'fold':
          playFold();
          break;
        default:
          break;
      }
    }
  }, [hand, playSound, lastplayer]);

  const leftPaneWidth = showLeftPane ? LEFT_PANE_WIDTH * currentScaleRef.current : 0;

  useLayoutEffect(() => {
    if (elementRef?.current) {
      const request = requestAnimationFrame(() => {
        const gameScreenHeight = elementRef.current?.offsetHeight ?? 0;
        const gameScreenWidth = elementRef.current?.offsetWidth ?? 0;
        const scale = getTransformScale(gameScreenWidth, gameScreenHeight);
        currentScaleRef.current = scale;
        setScale(scale);
      });

      return () => cancelAnimationFrame(request);
    }
  }, [elementRef, setScale, showLeftPane]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const gameScreenHeight = elementRef.current?.offsetHeight ?? 0;
      const gameScreenWidth = elementRef.current?.offsetWidth ?? 0;
      const scale = getTransformScale(gameScreenWidth, gameScreenHeight);
      currentScaleRef.current = scale;
      setScale(scale);
    });

    return () => window.removeEventListener('resize', () => {});
  }, []);

  if (!currentTable) {
    return <LoadingState />;
  }

  return (
    <div style={{ display: 'flex', color: 'white', backgroundColor: '#1a1a1a' }}>
      <div style={{ position: 'relative', width: leftPaneWidth }}>
        <TableHistory />
        <Chat />
      </div>
      <div style={{ ...gameStyle, width: `calc(100% - ${leftPaneWidth}px)` }} ref={elementRef}>
        {players.length === 0 && !isDuplicateAccess && <SoftLoadingState />}
        <GameCommsMenu />
        <UserActions />
        <Toaster />
        <Table />
        <PlayerTables />
        <BuyInModal />
        <DuplicateScreenModal />
        {(tableType === 'm' || tableType === 't') && <TournamentScreenModal />}
        <WaitForBuyInModal />
        <AddChipsModal />
        <AddOnChipsModal />
        <RebuyChipsModal />
        <AddFundsModal />
        <ErrorMsgModal />
        <AutoFoldMessageModal />
        <FreshDeskLink />
        {(tableType === 'm' || tableType === 't') && tStatus !== 'C' && (
          <TournamentDetails
            tableType={tableType}
            gameTitle={title}
            tournamentId={tournamentId}
            tournamentPrizePool={prizePool}
            tournamentStatus={tStatus}
          />
        )}
      </div>
    </div>
  );
};

export default Game;

const gameStyle: CSSProperties = {
  position: 'relative',
  overflow: 'auto',
  background: 'radial-gradient(circle,rgba(181, 97, 65, 1) 35%,rgba(117, 64, 43, 1) 100%)',
  minHeight: '100vh',
  maxHeight: '100vh',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};
