import React, { useContext } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import GameContext from 'contexts/GameContext';
import GlobalContext from 'contexts/GlobalContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

const JoinButton = () => {
  const { imBack } = useGameService();
  const { setOpenAskBuyin } = useContext(GlobalContext);
  const { setIsSatOut, setIsStandingUp } = useContext(GameContext);
  const { hand, waitlistCount } = useAppSelector((state) => state.currentTable.game);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const isWaitingAndExit = useAppSelector(
    (state) => state.currentTable.currentPlayer.isWaitingAndExit
  );
  const isRejoining = useAppSelector((state) => state.currentTable.currentPlayer.isRejoining);

  const triggerFunc = () => {
    if (isWaitingAndExit || isRejoining) {
      setIsSatOut(false);
      setIsStandingUp(false);
      imBack(seatId);
    } else {
      setOpenAskBuyin(true);
      setIsSatOut(false);
      setIsStandingUp(false);
    }
  };

  return (
    <>
      {waitlistCount === 0 && (
        <Base variant='contained' onClick={() => (hand === '' || +hand > 3) && triggerFunc()}>
          {seatId > 0 && (isWaitingAndExit || isRejoining) ? 'Rejoin Table' : 'Join Table'}
        </Base>
      )}
    </>
  );
};

export default JoinButton;

const Base = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText('#20262D'),
  backgroundColor: '#20262D',
  '&:hover': {
    color: theme.palette.getContrastText('#E98C26'),
    backgroundColor: '#E98C26'
  },
  position: 'absolute',
  bottom: '1rem',
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '1rem',
  fontWeight: '700',
  textTransform: 'none',
  padding: '1rem 2.5rem',
  zIndex: 2
}));
